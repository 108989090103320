import axios from "axios";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { PulseLoader } from "react-spinners";
import ApiConfig from "../ApiConfig";
import { FiRefreshCcw } from "react-icons/fi";
import { SiMicrosoftexcel } from "react-icons/si";
import {
  useDownloadExcel,
  DownloadTableExcel,
} from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";
import "./Home.scss";

export default function SessionPage() {
  const [activeUsernames, setUsernames] = useState([]);
  const [sessionResponseUsers, setSessionRespUsers] = useState([]);
  const [sessionEvalResponse, setSessionEvalResp] = useState([]);
  const [activeUsersLoader, setActiveLoader] = useState(false);
  const [resLoader, setResLoader] = useState(false);
  const [EvalLoader, setEvalLoader] = useState(false);
  const [tab, setTab] = useState("session");
  const [title, setTitle] = useState(true);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isDateActive, setIsDateActive] = useState(false);
  const [selected, setSelected] = useState("Latest session");
  const [userresponse, setUserResponse] = useState([]);
  const [evalRespSummary, setEvalRespSummary] = useState([]);
  const [sessionSummary, setSessionSummary] = useState([]);
  const [distinctuserresponse, setDistinctUserResponse] = useState(0);
  const [distinctevalresp, setDistinctEvalResp] = useState(0);
  const [distinctsessionusers, setDistinctSessionUsers] = useState(0);
  const [option, setOption] = useState("Latest session");
  const [userGroups, setuserGroups] = useState([]);
  const [userGroup, setuserGroup] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [teacher, setTeacher] = useState("");
  const [track, setTracks] = useState([]);
  const [selectedtrack, setSelectedtrack] = useState("");
  let Api = new ApiConfig();

  useEffect(() => {
    // setSelected('');
    evalResponseSummary(selected);
    getUserResposneRecords(selected);
    userSessionSummary(selected);
    getDistinctUserResponse(selected);
    getDistinctEvalResponse(selected);
    getDistinctSessionUsers(selected);
    setOption(selected);
    setIsActive(false);
    setTitle(false);
  }, [selectedtrack, userGroup]);

  function GetUSerGroups() {
    axios.get(Api.addApiKey(`${Api.BaseURI}/getusergroups`)).then((res) => {
      setuserGroups([]);
      for (let i = 0; i < res.data.length; i++) {
        if (
          !userGroups.includes(res.data[i].user_group) &
          userGroups.indexOf(res.data[i].user_group == -1)
        ) {
            setuserGroups((userGroups) =>
            userGroups.concat(res.data[i].user_group)
          );
        }
      }
    });
  }

  function getTeachers() {
    axios
      .get(Api.addApiKey(`${Api.BaseURI}/getteachers?usergroup=${userGroup}`))
      .then((res) => {
        setTeachers([]);
        for (let i = 0; i < res.data.length; i++) {
          if (
            !teachers.includes(res.data[i].teacher) &
            teachers.indexOf(res.data[i].userid == -1)
          ) {
            console.log(res.data[i].userid);

            setTeachers((teachers) => teachers.concat(res.data[i].userid));
          }
        }
        console.log(teachers);
      });
  }



  async function getUserResposneRecords(title, date1, date2, teacher) {
    setResLoader(true);

    // console.log("api", (Api.addApiKey(`${Api.BaseURI}/getuserresponsesummary?usergroup=${userGroup}&title=${title}&date1=${date1}&date2=${date2}&&teacher=${teacher}`)));
    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI}/getuserresponsesummary?usergroup=${userGroup}&title=${title}&date1=${date1}&date2=${date2}&userid=${teacher}&track=${selectedtrack}`
        )
      )
      .then((resp) => {
        if (resp.data.status === 200) {
          setUserResponse(resp.data.result);
        }
        setResLoader(false);
      });

  }

  async function evalResponseSummary(title, date1, date2, teacher) {
    setEvalLoader(true);

    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI}/getevalresponsesummary?usergroup=${userGroup}&title=${title}&date1=${date1}&date2=${date2}&userid=${teacher}&track=${selectedtrack}`
        )
      )
      .then((resp) => {
        if (resp.data.status === 200) {
          console.log("resp.data.result", resp.data.result);
          setEvalRespSummary(resp.data.result);
        }
        setEvalLoader(false);
      });
  }

  async function userSessionSummary(title, date1, date2, teacher) {
    setActiveLoader(true);
   
    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI}/getsessionsummary?usergroup=${userGroup}&title=${title}&date1=${date1}&date2=${date2}&userid=${teacher}&track=${selectedtrack}`
        )
      )
      .then((resp) => {
        if (resp.data.status === 200) {
          console.log("resp.data.result", resp.data.result);
          setSessionSummary(resp.data.result);
        }
        setActiveLoader(false);
      });
  }

  async function getDistinctUserResponse(title, date1, date2, teacher) {
    setResLoader(true);
   
    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI}/distinctuserresponse?usergroup=${userGroup}&title=${title}&date1=${date1}&date2=${date2}&userid=${teacher}&track=${selectedtrack}`
        )
      )
      .then((resp) => {
        if (resp.data.status === 200) {
          setDistinctUserResponse(resp.data.result[0]);
        }
        setResLoader(false);
      });
  }

  async function getDistinctEvalResponse(title, date1, date2, teacher) {
    setEvalLoader(true);
 
    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI}/distinctevalresponse?usergroup=${userGroup}&title=${title}&date1=${date1}&date2=${date2}&userid=${teacher}&track=${selectedtrack}`
        )
      )
      .then((resp) => {
        if (resp.data.status === 200) {
          console.log("getDistinctEvalResponse", resp.data.result);
          setDistinctEvalResp(resp.data.result[0]);
        }
        setEvalLoader(false);
      });
  }

  async function getDistinctSessionUsers(title, date1, date2, teacher) {
    setActiveLoader(true);

    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI}/distinctsessionusers?usergroup=${userGroup}&title=${title}&date1=${date1}&date2=${date2}&userid=${teacher}&track=${selectedtrack}`
        )
      )
      .then((resp) => {
        if (resp.data.status === 200) {
          console.log(
            "getDistinctSessionUsers",
            resp.data.result[0].user_count
          );
          setDistinctSessionUsers(resp.data.result[0].user_count);
        }
        setActiveLoader(false);
      });
  }

  async function GetTrack() {
    let result = await axios.get(Api.addApiKey(`${Api.BaseURI}/tracks`));
    let resultlabel = result?.data.map((item) => item.track);
    setTracks(resultlabel);
    console.log(
      "res",
      Api.addApiKey(`${Api.BaseURI}/tracks`),
      "datae",
      resultlabel
    );
  }

  useEffect(() => {

    GetUSerGroups();
    GetTrack();

  }, []);



  let teachersSelect = useMemo(() => {
    getTeachers();
  }, [userGroup]);



  function setActiveUsersTable() {
    let activeuserarr =
      sessionSummary.length === 0 ? activeUsernames : sessionSummary;
    // console.log(new Date(activeuserarr[0].login))
    return (
      <>
        {/* <div className='table-exe'>
				<table className='table-exe'>
					<tr>
						<th>#</th>
						<th>Username</th>
						<th>login</th>
					</tr>

					{activeuserarr.map((items, index) =>
						<tr>
							<td>{index + 1}</td>
							<td>{items.username}</td>
							<td>{new Date(items.login).toLocaleString()}</td>
						</tr>
					)}
				</table>
			</div> */}

        <div class="container">
          <div class="table-wrap">
            <table class="table table-responsive table-borderless">
              <thead>
                <th>Index</th>
                <th>Username</th>
                <th>Login</th>
              </thead>
              <tbody>
                {activeuserarr.map((items, index) => (
                  <tr class="align-middle alert border-bottom" role="alert">
                    <td>{index + 1}</td>
                    <td>{items.Email}</td>
                    <td>{items.Login}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  function setResponseTable() {
    // console.log(userresponse)
    let resparr =
      userresponse.length === 0 ? sessionResponseUsers : userresponse;
    console.log("userresponce", userresponse);
    return (
      <>
        {/* <div className='table-exe'>
				<table className='table-exe'>
					<tr>
						<th>#</th>
						<th>Username</th>
						<th>Exercises completed</th>
						<th>Score</th>
						<th>Total marks</th>
						<th>Badge points earned</th>
					</tr>

					{resparr.map((items, index) =>
						<tr>
							<td>{index + 1}</td>
							<td>{items.userid}</td>
							<td>{items.excount}</td>
							<td>{items.score}</td>
							<td>{items.total}</td>
							<td>{items.badges}</td>
						</tr>
					)}
				</table>
			</div> */}

        <div class="container">
          <div class="table-wrap">
            <table class="table table-responsive table-borderless">
              <thead>
                <th>Index</th>
                <th>Username</th>
                <th>Exercises completed</th>
                <th>Score</th>
                <th>Total marks</th>
                <th>Badge points earned</th>
              </thead>
              <tbody>
                {resparr.map((items, index) => (
                  <tr class="align-middle alert border-bottom" role="alert">
                    <td>{index + 1}</td>
                    <td>{items.Email}</td>
                    <td>{items.Exercises_completed}</td>
                    <td>{items.Score}</td>
                    <td>{items.Total_marks}</td>
                    <td>{items.Badge_points_earned}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  function setEvalRespTable() {
    let evalarr =
      evalRespSummary.length === 0 ? sessionEvalResponse : evalRespSummary;
    return (
      <>
        {/* <div className='table-exe'>
				<table className='table-exe'>
					<tr>
						<th>#</th>
						<th>Username</th>
						<th>Evaluation name</th>
						<th>No of correct answers</th>
						<th>Total no of  questions</th>
						<th>Score</th>
						<th>Total score</th>
					</tr>

					{evalarr.map((items, index) =>
						<tr>
							<td>{index + 1}</td>
							<td>{items.userid}</td>
							<td>{items.module_name}</td>
							<td>{items.no_of_correct_answers}</td>
							<td>{items.no_of_total_questions}</td>
							<td>{items.score}</td>
							<td>{items.total_marks}</td>
						</tr>
					)}
				</table>
			</div> */}

        <div class="container">
          <div class="table-wrap">
            <table class="table table-responsive table-borderless">
              <thead>
                <th>Index</th>
                <th>Username</th>
                <th>First_name</th>
                <th>Last_name</th>
                <th>Evaluation_name</th>
                <th>Score</th>
                <th>Total marks</th>
              </thead>
              <tbody>
                {evalarr.map((items, index) => (
                  <tr class="align-middle alert border-bottom" role="alert">
                    <td>{index + 1}</td>
                    <td>{items.Email}</td>
                    <td>{items.First_Name}</td>
                    <td>{items.Last_Name}</td>
                    <td>{items.Evaluation_name}</td>
                    <td>{items.Score}</td>
                    <td>{items.Total_marks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  function toDownloadCsv() {
    let dataarr;
    if (tab === "session") {
      dataarr = sessionSummary.length === 0 ? activeUsernames : sessionSummary;
    } else if (tab === "exe") {
      dataarr = userresponse.length === 0 ? sessionResponseUsers : userresponse;
    } else if (tab === "eval") {
      dataarr =
        evalRespSummary.length === 0 ? sessionEvalResponse : evalRespSummary;
    }
    return dataarr;
  }

  const ActiveUsersTable = useMemo(() => {
    setActiveLoader(false);
    return setActiveUsersTable();
  }, [activeUsernames]);

  const ResponseTable = useMemo(() => {
    setResLoader(false);
    return setResponseTable();
  }, [sessionResponseUsers]);

  const EvalRespTable = useMemo(() => {
    setEvalLoader(false);
    return setEvalRespTable();
  }, [sessionEvalResponse]);

  let menuRef = useRef();
  // console.log("menuRef", menuRef)
  useMemo(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsActive(false);
        setIsDateActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  function setDropDown() {
    return (
      <>
        <div ref={menuRef} className="pyui_session">
          <div
            className={`pyui_session-inner ${isActive ? "expanded" : ""}`}
            onClick={(e) => setIsActive(!isActive)}
          >
            <span>{selected}</span>
          </div>
          {isActive && (
            <ul className="pyui_session-list">
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  setSelected("session");

                  evalResponseSummary("session", "", "", teacher);
                  getUserResposneRecords("session", "", "", teacher);
                  userSessionSummary("session", "", "", teacher);
                  getDistinctUserResponse("session", "", "", teacher);
                  getDistinctEvalResponse("session", "", "", teacher);
                  getDistinctSessionUsers("session", "", "", teacher);
                  setIsActive(false);
                  setOption("Latest session");
                  // getSessionInfo()
                  // getUserEvalResp()
                  // getUserSessionResp()
                  setTitle(true);
                }}
              >
                Latest session
              </li>
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  setSelected("today");
                  // getUserResposneRecords();

                  evalResponseSummary("today");

                  getUserResposneRecords("today");
                  userSessionSummary("today");
                  getDistinctUserResponse("today");
                  getDistinctEvalResponse("today");
                  getDistinctSessionUsers("today");
                  setOption("today");
                  setIsActive(false);
                  setTitle(false);
                }}
              >
                Today
              </li>
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('7days')
                  setSelected("7days");

                  evalResponseSummary("7days");

                  getUserResposneRecords("7days");
                  userSessionSummary("7days");
                  getDistinctUserResponse("7days");
                  getDistinctEvalResponse("7days");
                  getDistinctSessionUsers("7days");
                  setOption("7days");
                  setIsActive(false);
                  setTitle(false);
                }}
              >
                Last 7 days
              </li>
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('15days');
                  setSelected("15days");

                  evalResponseSummary("15days");

                  getUserResposneRecords("15days");
                  userSessionSummary("15days");
                  getDistinctUserResponse("15days");
                  getDistinctEvalResponse("15days");
                  getDistinctSessionUsers("15days");
                  setOption("15days");
                  setIsActive(false);
                  setTitle(false);
                }}
              >
                Last 15 days
              </li>
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('30days');
                  setSelected("30days");

                  evalResponseSummary("30days");

                  getUserResposneRecords("30days");
                  userSessionSummary("30days");
                  getDistinctUserResponse("30days");
                  getDistinctEvalResponse("30days");
                  getDistinctSessionUsers("30days");
                  setOption("30days");
                  setIsActive(false);
                  setTitle(false);
                }}
              >
                Last 30 days
              </li>
              <li
                className={`pyui_session-list-item-date ${
                  isDateActive ? "expand" : ""
                }`}
                onClick={(e) => {
                  setIsDateActive(!isDateActive);
                  // setTitle('custom');
                  // getUserResposneRecords()
                  setSelected("custom");
                  setTitle(false);
                }}
              >
                <span className="date-picker-title">Custom date range</span>
              </li>
              {isDateActive && (
                <li className="pyui_session-list-date">
                  <div className="custom-date">
                    <input
                      className="date-input-field"
                      value={date1}
                      onChange={(e) => setDate1(e.target.value)}
                      type="date"
                    />
                    to
                    <input
                      className="date-input-field"
                      value={date2}
                      onChange={(e) => {
                        setDate2(e.target.value);
                      }}
                      type="date"
                    />
                    <button
                      className="primary-button"
                      onClick={() => {
                        evalResponseSummary("custom", date1, date2);
                        getUserResposneRecords("custom", date1, date2);
                        userSessionSummary("custom", date1, date2);
                        getDistinctUserResponse("custom", date1, date2);
                        getDistinctEvalResponse("custom", date1, date2);
                        getDistinctSessionUsers("custom", date1, date2);
                        setOption("custom");
                        setIsActive(false);
                        setIsDateActive(!isDateActive);
                        // setTitle(false);
                      }}
                    >
                      Get details
                    </button>
                  </div>
                </li>
              )}
            </ul>
          )}
        </div>
      </>
    );
  }

  function setSummaryTitle(user_count) {
    // console.log("user_count", user_count);
    // let user_count = usercount.user_count;
    let title = "";
    let user = user_count > 1 ? "users" : "user";
    if (tab === "session") {
      if (option === "Latest session") {
        title = `Total ${user_count} ${user} logged in during latest session.`;
      } else if (option === "today") {
        title = `Total ${user_count} ${user} logged in during today's session.`;
      } else if (option === "7days") {
        title = `Total ${user_count} ${user} logged in during last 7 days.`;
      } else if (option === "15days") {
        title = `Total ${user_count} ${user} logged in during last 15 days.`;
      } else if (option === "30days") {
        title = `Total ${user_count} ${user} logged in during last 30 days.`;
      } else if (option === "custom") {
        title = `Total ${user_count} ${user} logged in between ${date1} and ${date2}.`;
      }
    }
    return title;
  }

  function setExerciseSumTitle(distinctuserresponse) {
    // console.log("distinctuserresponse", distinctuserresponse);
    let distinct_excount = parseInt(distinctuserresponse.distinct_excount) || 0;
    let user_count = parseInt(distinctuserresponse.user_count) || 0;
    let excount = parseInt(distinctuserresponse.excount) || 0;
    // console.log("distinct_excount", distinct_excount);
    // console.log("user_count", user_count);
    // console.log("excount", excount);
    let title = "";
    let user = user_count > 1 ? "users" : "user";
    let exercises = distinct_excount > 1 ? "exercises" : "exercise";
    if (tab === "exe") {
      if (option === "Latest session") {
        title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during latest session`;
      } else if (option === "today") {
        title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during today's session.`;
      } else if (option === "7days") {
        title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during last 7 days.`;
      } else if (option === "15days") {
        title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during last 15 days.`;
      } else if (option === "30days") {
        title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during last 30 days.`;
      } else if (option === "custom") {
        title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises between ${date1} and ${date2}.`;
      }
    }
    // console.log("title", title);
    return title;
  }

  function setEvalSumTitle(distinctevalresp) {
    console.log("distinctevalresp", distinctevalresp);
    let distinct_mdcount = parseInt(distinctevalresp.distinct_mdcount) || 0;
    let user_count = parseInt(distinctevalresp.user_count) || 0;
    let module_count = parseInt(distinctevalresp.module_count) || 0;
    // console.log("distinct_mdcount", distinct_mdcount);
    // console.log("user_count", user_count);
    // console.log("module_count", module_count);
    let title = "";
    let user = user_count > 1 ? "users" : "user";
    let modules = distinct_mdcount > 1 ? "evaluations" : "evaluation";
    if (tab === "eval") {
      if (option === "Latest session") {
        title = `Total ${user_count} ${user} submitted ${module_count} ${modules} out of which ${distinct_mdcount} are distinct`;
      } else if (option === "today") {
        title = `Total ${user_count} ${user} submitted ${module_count} ${modules} out of which ${distinct_mdcount} are distinct today`;
      } else if (option === "7days") {
        title = `Total ${user_count} ${user} submitted ${module_count} ${modules} out of which ${distinct_mdcount} are distinct during last 7 days.`;
      } else if (option === "15days") {
        title = `Total ${user_count} ${user} submitted ${module_count} ${modules} out of which ${distinct_mdcount} are distinct during last 15 days.`;
      } else if (option === "30days") {
        title = `Total ${user_count} ${user} submitted ${module_count} ${modules} out of which ${distinct_mdcount} are distinct during last 30 days.`;
      } else if (option === "custom") {
        title = `Total ${user_count} ${user} submitted ${module_count} ${modules} out of which ${distinct_mdcount} are distinct between ${date1} and ${date2}.`;
      }
    }
    return title;
  }

  function csvLink(filename) {
    if (tab === "session") {
      return <></>;
    }
  }

  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    // borderColor: "olive",
    // backgroundColor: "red",
    // width: "100%",
  };
  return (
    <>
      <div className="content" style={{ marginTop: 100, display: "flex" }}>
        {" "}
        {/* //marginLeft:100 */}
        <Box
          sx={{ width: 230, height: 50, marginLeft: "30px", marginBottom: 2 }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select group</InputLabel>
            <Select
              sx={{ height: "50px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={userGroup}
              label="select role"
              onChange={(e) => setuserGroup(e.target.value)}
            >
              {userGroups.map((data, index) => (
                <MenuItem key={index} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {/* {teachersSelect} */}
        <Box
          sx={{ width: 270, height: 50, marginLeft: "30px", marginBottom: 2 }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select teacher/admin
            </InputLabel>
            <Select
              sx={{ height: "50px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={teacher}
              label="select role"
              onChange={(e) => setTeacher(e.target.value)}
            >
              {teachers.map((data, index) => (
                <MenuItem key={index} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{ width: 270, height: 50, marginLeft: "30px", marginBottom: 2 }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Track</InputLabel>
            <Select
              sx={{ height: "50px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedtrack}
              label="select role"
              onChange={(e) => setSelectedtrack(e.target.value)}
            >
              {track.map((data, index) => (
                <MenuItem key={index} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <span className="select-interval">
          <span className="interval-title">Select interval</span>
          {setDropDown()}
          {selected == "session" && (
            <button
              title="Refresh"
              onClick={() => {
                setSelected("session");
                evalResponseSummary("session", "", "", teacher);
                getUserResposneRecords("session", "", "", teacher);
                userSessionSummary("session", "", "", teacher);
                getDistinctUserResponse("session", "", "", teacher);
                getDistinctEvalResponse("session", "", "", teacher);
                getDistinctSessionUsers("session", "", "", teacher);
                setIsActive(false);
                setOption("Latest session");
                setTitle(true);
              }}
              style={{ padding: "0.9rem 1rem" }}
              className={
                title === true ? "primary-button" : "primary-button disabled"
              }
            >
              <FiRefreshCcw />
            </button>
          )}
        </span>
      </div>

      <div className="content" style={{ marginBottom: 100 }}>
        {" "}
        <section className="pyui_main-content-section">
     
          <span className="session-tabs">
            <p
              className={tab === "session" ? "tab-selected" : "pyui_main-tabs2"}
              onClick={() => {
                setTab("session");
              }}
            >
              Session summary
            </p>
            <p
              className={tab === "exe" ? "tab-selected" : "pyui_main-tabs2"}
              onClick={() => {
                setTab("exe");
              }}
            >
              Exercises completed
            </p>
            <p
              className={tab === "eval" ? "tab-selected" : "pyui_main-tabs2"}
              onClick={() => {
                setTab("eval");
              }}
            >
              Evalutions completed
            </p>
          </span>
          {tab === "session" && (
            <>
              <div className="pyui-download-buttons">
                <h3>{setSummaryTitle(distinctsessionusers)}</h3>

                {/* <button title='Refresh' onClick={() => { getSessionInfo() }} style={{ padding: '0.5rem 1rem' }} className='primary-button'>
								<FiRefreshCcw />
							</button> */}
                {/* <DownloadTableExcel
								filename="Logged in students table"
								sheet="Logged in students"
								currentTableRef={tableActiveUsersTableRef.current}
							>
								<button title='Excel' style={{ padding: '0.5rem 1rem' }} className='primary-button'><SiMicrosoftexcel /></button>
							</DownloadTableExcel> */}
                <button
                  title="Excel"
                  style={{ padding: "0.9rem 1rem" }}
                  className={
                    sessionSummary.length === 0 && activeUsernames.length === 0
                      ? "primary-button disabled"
                      : "primary-button"
                  }
                >
                  <CSVLink
                    data={toDownloadCsv()}
                    filename={"logged_in_users.csv"}
                  >
                    <SiMicrosoftexcel color="white" />
                  </CSVLink>
                </button>
              </div>
              {activeUsersLoader ? (
                <p className="formloader-spinner">
                  Loading
                  <PulseLoader
                    color="#1c84c3"
                    loading={activeUsersLoader}
                    cssOverride={override}
                    size={3}
                    className="pulse-loader"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={0.5}
                  />
                </p>
              ) : (
                <>{setActiveUsersTable()}</>
              )}
            </>
          )}
          {tab === "exe" && (
            <>
              <div className="pyui-download-buttons">
                <h3>{setExerciseSumTitle(distinctuserresponse)} </h3>

            
                <button
                  title="Excel"
                  style={{ padding: "0.9rem 1rem" }}
                  className={
                    userresponse.length === 0 &&
                    sessionResponseUsers.length === 0
                      ? "primary-button disabled"
                      : "primary-button"
                  }
                >
                  <CSVLink
                    data={toDownloadCsv()}
                    filename={"Exercises_completed.csv"}
                  >
                    <SiMicrosoftexcel color="white" />
                  </CSVLink>
                </button>
              </div>
              {resLoader ? (
                <p className="formloader-spinner">
                  Loading
                  <PulseLoader
                    color="#1c84c3"
                    loading={resLoader}
                    cssOverride={override}
                    size={3}
                    className="pulse-loader"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={0.5}
                  />
                </p>
              ) : (
                <>{setResponseTable()}</>
              )}
            </>
          )}
          {tab === "eval" && (
            <>
              <div className="pyui-download-buttons">
                <h3>{setEvalSumTitle(distinctevalresp)}</h3>
                {/* <button title='Refresh' onClick={() => { getUserEvalResp() }} style={{ padding: '0.5rem 1rem' }} className='primary-button'><FiRefreshCcw /></button> */}

                {/* <DownloadTableExcel
								filename="Evaluations completed"
								sheet="Evaluations"
								currentTableRef={tableEvalRespTableRef.current}
							>
								<button title='Excel' style={{ marginRight: '1rem', padding: '0.5rem 1rem' }} className='primary-button'><SiMicrosoftexcel /></button>
							</DownloadTableExcel> */}

                <button
                  title="Excel"
                  style={{ padding: "0.9rem 1rem" }}
                  className={
                    evalRespSummary.length === 0 &&
                    sessionEvalResponse.length === 0
                      ? "primary-button disabled"
                      : "primary-button"
                  }
                >
                  <CSVLink
                    data={toDownloadCsv()}
                    filename={"Evaluations_completed.csv"}
                  >
                    <SiMicrosoftexcel color="white" />
                  </CSVLink>
                </button>
              </div>
              {EvalLoader ? (
                <p className="formloader-spinner">
                  Loading
                  <PulseLoader
                    color="#1c84c3"
                    loading={EvalLoader}
                    cssOverride={override}
                    size={3}
                    className="pulse-loader"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={0.5}
                  />
                </p>
              ) : (
                <>{setEvalRespTable()}</>
              )}
            </>
          )}
        </section>
      </div>
    </>
  );
}
