import React, {  } from "react";
// import "@fontsource/roboto/400.css";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";

import praviLogo from "../../assets/Pravi Logo_2.png";
import certifiateBg from "../../assets/certbg.png";
import { date_helper } from "../UtilityFunctions/getCurrentWeek copy";
// import { date_helper } from "../../UtilityFunctions/getCurrentWeek";
import { useReactToPrint } from 'react-to-print';
// import "./certificates1.scss";

function Dialogcertificate({open,setOpen,fname,lname,downloadUserCertificates,setShowFeedback,returnLeague,toSetDate,usercertificates,index,selecttrack,certificateRefs,isadmin}){
    const toastId = React.useRef(null);

    const handleClose = () => {
        setOpen(false);
      };

    function fullName() {
      
        let fullName;

        if (
          fname !== undefined &&
          lname !== undefined &&
          fname !== null &&
          lname !== null &&
          fname !== "null" &&
          lname !== "null" &&
          fname !== "" &&
          lname !== ""
        ) {
          fullName = `${fname} ${lname}`;
        } else {
          fullName = "";
        }
        // console.log("fullName", fullName);
        return fullName;
      }



      function returnTrack(track) {
        if (track === "C") return "C Programming with Data Structures";
        else if (track === "Python") return "Python Programming";
        else if (track === "Visual Programming") return "Visual Programming";
      }
    

 
     
      function returnModule() {
     
        if (
          usercertificates !== undefined &&
          usercertificates[index] !== undefined
        ) {
          let module = usercertificates[index].module;
          if (module === "reader") return "Code Reading";
          else if (module === "debug") return "Code Debugging";
          else if (module === "solver") return "Problem Solving";
        }
      }

      function returnDuration() {
        if (
          usercertificates !== undefined &&
          usercertificates[index] !== undefined
        ) {
          return usercertificates[index].duration;
        }
      }

    const handlePrint = useReactToPrint({
        content: () => certificateRefs.current[index].current,
        documentTitle: `${fullName()}_${returnTrack(selecttrack)}_${returnModule()}`,
        onAfterPrint: () => {
          // Reset the Promise resolve so we can print again
          certificateRefs.current[index].current = null;
          handleClose(false);
        }
      });

    return(
        <>
        
        <Dialog fullScreen open={open} onClose={handleClose}>
        <div className="certificateDialog">
          <div className="actionBar">
            <button
              className="link-button"
              onClick={handleClose}
              aria-label="close"
            >
              &lt; Back
            </button>
            <button
              className="button secondary small"
              onClick={(e) => {
                e.preventDefault();
                if (
                  fname !== undefined &&
                  lname !== undefined &&
                  fname !== null &&
                  lname !== null &&
                  fname !== "null" &&
                  lname !== "null" &&
                  fname !== "" &&
                  lname !== ""
                ) {
                
                  handlePrint()
                  downloadUserCertificates();
                } else {
                  if (!toast.isActive(toastId.current)) {
                    return (toastId.current = toast.warn(
                      "You must provide first name and last name! Please edit the profile.",
                      { position: "top-center" }
                    ));
                  }
                }
             {!isadmin &&  setShowFeedback(true);}
              }}
            >
              Download
            </button>
          </div>
          <div className="certificatealigncenter" ref={certificateRefs.current[index]}>
            <div class=" pm-certificate-container" >
              <img src={certifiateBg} className="certificatebgImgage" />
              <div class="outer-border"></div>
              <div class="inner-border"></div>
              <div class="pm-certificate-border col-xs-12">
                <div class="row pm-certificate-header">
                  <div class="pm-certificate-title">
                    <img src={praviLogo} alt="CertificateBeginner" />
                    <h2 class="cert-title">Certificate of Completion</h2>
                    <h3 class="cert-subtitle">
                      This certificate is proudly awarded to
                    </h3>
                  </div>
                </div>

                <div class="row pm-certificate-body">
                  <div class="pm-certificate-block">
                    <div class="col-xs-12">
                      <div class="row">
                        <div class="col-xs-2"></div>
                        <div class="pm-certificate-name underline margin-0 col-xs-8 text-center">
                          <span class="pm-name-text bold">{fullName()}</span>
                        </div>
                        <div class="col-xs-2"></div>
                      </div>
                    </div>

                    <div class="col-xs-12">
                      <div class="row details">
                        {/* <div class="col-xs-2"></div> */}
                        <div class="pm-earned col-xs-8 text-center track">
                          <span class="pm-earned-text padding-0 block cursive">
                            TRACK
                          </span>
                          <span class="pm-credits-text block bold sans">
                            {returnTrack(selecttrack)}
                          </span>
                        </div>
                        <div class="pm-earned pm-skill col-xs-8 text-center">
                          <span class="pm-earned-text padding-0 block cursive">
                            SKILL
                          </span>
                          <span class="pm-credits-text block bold sans">
                            {returnModule()}
                          </span>
                        </div>
                        <div class="pm-earned col-xs-8 text-center">
                          <span class="pm-earned-text padding-0 block cursive">
                            LEVEL
                          </span>
                          <span class="pm-credits-text block bold sans">
                            {/* {league(returnLeague()).title} */}
                            {returnLeague()}
                          </span>
                        </div>
                        <div class="pm-earned col-xs-8 text-center">
                          <span class="pm-earned-text padding-0 block cursive">
                            DURATION
                          </span>
                          <span class="pm-credits-text block bold sans">
                            {returnDuration()} hrs
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 addressrow">
                    <div class="pm-earned  pm-company col-xs-8 text-center dateisued">
                      <span class="pm-earned-text padding-0 block">
                        DATE ISSUED
                      </span>
                      <span class="pm-credits-text block bold sans">
                        {date_helper(toSetDate())}
                      </span>

                      <span class="pm-credits-text block bold sans">
                        <br></br>
                      </span>
                    </div>
                    <div class="pm-earned pm-company col-xs-8 text-center">
                      {/* <img
                        // src={league(items.league)} 
                        src={returnBadge(returnLeague())}
                        alt="CertificateBeginner" width="110px" height="120px" /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="75"
                        height="84"
                        viewBox="0 0 53.456 64.199"
                      >
                        <g
                          id="Component_3_3"
                          data-name="Component 3 – 3"
                          transform="translate(0.728 0.747)"
                        >
                          <path
                            id="Path_21285"
                            data-name="Path 21285"
                            class="cls-1"
                            d="M325.466,245.858c-1.494-.077-2.9-.146-4.3-.221-1.507-.082-3.015-.157-4.521-.259-.326-.024-.407.164-.519.383q-1.821,3.581-3.647,7.159c-.091.176-.186.35-.323.609-2.029-3.526-4.03-7-6.026-10.472a2.563,2.563,0,0,0,1.291-.881c.628-.73,1.19-1.519,1.751-2.3a3.46,3.46,0,0,1,3.1-1.522,19.592,19.592,0,0,0,2.957.04c1.165-.149,2.019-1.124,2.427-2.646.175-.653.345-2.146.471-2.556Z"
                            transform="translate(-274.113 -191.629)"
                          ></path>
                          <path
                            id="Path_21286"
                            data-name="Path 21286"
                            class="cls-1"
                            d="M249.649,242.994c-1.994,3.466-3.992,6.94-6.017,10.459-1.321-2.594-2.612-5.088-3.858-7.6a.838.838,0,0,0-.976-.536c-2.786.177-5.576.31-8.367.455-.037-.221.105-.34.182-.476q3.486-6.048,6.979-12.091a14.745,14.745,0,0,1,.847,3.048,2.551,2.551,0,0,0,2.687,2.153c.789.032,1.581-.025,2.371-.072a3.728,3.728,0,0,1,2.026.4.174.174,0,0,0,.032.018,3.83,3.83,0,0,1,1.311,1.236,22.7,22.7,0,0,0,1.636,2.148A2.933,2.933,0,0,0,249.649,242.994Z"
                            transform="translate(-229.712 -191.636)"
                          ></path>
                          <path
                            id="Path_21287"
                            data-name="Path 21287"
                            class="cls-1"
                            d="M280.161,154.894a20.91,20.91,0,0,0-1.76-2.165,3.438,3.438,0,0,1-.816-3.423c.243-.85.438-1.715.611-2.582a2.407,2.407,0,0,0-1.407-3c-.761-.41-1.549-.777-2.346-1.113a3.406,3.406,0,0,1-2.155-2.708,21.78,21.78,0,0,0-.6-2.848,2.394,2.394,0,0,0-2.488-1.869c-.867-.018-1.737-.028-2.6.027a3.368,3.368,0,0,1-3.137-1.592c-.51-.724-1.04-1.439-1.607-2.12a2.3,2.3,0,0,0-2.964-.706c-.832.36-1.64.787-2.437,1.22a3.48,3.48,0,0,1-3.53-.007c-.761-.412-1.527-.82-2.318-1.167a2.261,2.261,0,0,0-3.068.685c-.583.667-1.093,1.4-1.615,2.114a3.4,3.4,0,0,1-3.151,1.574c-.842-.059-1.693-.045-2.538-.027a2.4,2.4,0,0,0-2.538,1.894,15.242,15.242,0,0,0-.52,2.468,3.636,3.636,0,0,1-2.371,3.135,19.908,19.908,0,0,0-2.287,1.083,2.366,2.366,0,0,0-1.346,2.858,22.527,22.527,0,0,0,.586,2.519,3.7,3.7,0,0,1-.862,3.783,22.064,22.064,0,0,0-1.532,1.93,2.488,2.488,0,0,0,0,3.283c.553.773,1.169,1.5,1.791,2.222a3.357,3.357,0,0,1,.75,3.314c-.193.685-.357,1.378-.524,2.071-.479,1.993-.041,2.912,1.813,3.808.487.236.992.438,1.481.665a4.775,4.775,0,0,1,2.226,2.516,14.436,14.436,0,0,1,.835,3,2.515,2.515,0,0,0,2.65,2.117c.777.031,1.559-.025,2.338-.071a3.685,3.685,0,0,1,2,.39.187.187,0,0,0,.031.018,3.772,3.772,0,0,1,1.293,1.215,22.334,22.334,0,0,0,1.613,2.112,2.884,2.884,0,0,0,1.131.848,2.45,2.45,0,0,0,1.9-.183c.85-.374,1.664-.828,2.495-1.25a3.135,3.135,0,0,1,2.934-.133c.641.312,1.26.668,1.889,1,.317.134.633.273.951.4a2.683,2.683,0,0,0,1.7.194,2.531,2.531,0,0,0,1.273-.866c.619-.718,1.174-1.493,1.727-2.263a3.415,3.415,0,0,1,3.055-1.5,19.375,19.375,0,0,0,2.916.04,2.472,2.472,0,0,0,2.161-1.852c.277-.806.573-2.86.7-3.263v0A3.765,3.765,0,0,1,275,170.175a12.544,12.544,0,0,0,2.046-.983,2.375,2.375,0,0,0,1.282-2.774,22.632,22.632,0,0,0-.641-2.707,3.566,3.566,0,0,1,.8-3.5c.579-.67,1.138-1.365,1.646-2.087A2.512,2.512,0,0,0,280.161,154.894Z"
                            transform="translate(-228.74 -130.519)"
                          ></path>
                          <path
                            id="Path_21288"
                            data-name="Path 21288"
                            class="cls-2"
                            d="M260.073,140.72c.76.132,1.524.243,2.279.4a20,20,0,0,1,11.207,6.52,20.76,20.76,0,0,1,3.992,20.52,22.931,22.931,0,0,1-2.64,5.2c-.088.1-.185.192-.264.3a20.2,20.2,0,0,1-9,6.9c-.66.259-1.336.478-2,.715a20.5,20.5,0,0,1-10.343.017c-.168-.067-.332-.148-.505-.2a20.059,20.059,0,0,1-9.222-5.776c-.556-.6-1-1.292-1.575-1.878a32.839,32.839,0,0,1-1.808-3.139,20.08,20.08,0,0,1-2.028-9.492A20.409,20.409,0,0,1,244.926,146a19.96,19.96,0,0,1,10.457-5.051,4.933,4.933,0,0,0,1.3-.226Z"
                            transform="translate(-232.322 -134.773)"
                          ></path>
                          <path
                            id=""
                            data-name=""
                            class="star"
                            d="M275.221,190.354c.159-.941.326-1.934.5-2.927.079-.465.16-.93.247-1.394a.828.828,0,0,0-.319-.776q-1.443-1.4-2.883-2.811c-.212-.206-.441-.405-.329-.754s.4-.414.72-.46c1.326-.188,2.649-.4,3.976-.582a.932.932,0,0,0,.788-.569c.586-1.219,1.19-2.43,1.784-3.646.128-.262.268-.5.608-.5.358-.008.51.228.645.505.594,1.216,1.2,2.425,1.788,3.644a.927.927,0,0,0,.786.569c1.352.182,2.7.4,4.052.591.3.043.545.128.647.446s-.075.509-.272.7c-.947.933-1.88,1.881-2.849,2.791a1.149,1.149,0,0,0-.34,1.17c.234,1.292.444,2.589.672,3.883a.621.621,0,0,1-.979.689c-1.184-.628-2.379-1.236-3.556-1.878a1.045,1.045,0,0,0-1.11-.006c-1.2.653-2.413,1.28-3.626,1.907A.6.6,0,0,1,275.221,190.354Z"
                            transform="translate(-263.292 -167.065)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <div class="pm-earned pm-company col-xs-8 text-center providedby">
                      <span class="pm-earned-text padding-0 block cursive">
                        PROVIDED BY
                      </span>
                      <span class="pm-credits-text block bold sans">
                        EVOLVABILITY TECH PVT LTD
                      </span>
                      <span class="pm-credits-text block bold sans">
                        <br></br>
                      </span>
                    </div>
                    <h4 class="footer">
                      This is a computer-generated document. No signature is
                      required.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
        </>
    )
}

export default Dialogcertificate;