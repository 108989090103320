import axios from "axios";
import React, { useEffect, useMemo, useState, useRef, useReducer } from "react";
import { PulseLoader } from "react-spinners";
import ApiConfig from "../../ApiConfig";
// import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
// import Tabs from "../Tabs/Tabs";
import UserHistoryGrp from "../UtilityFunctions/UserHistoryGrp";
// import UserHistoryGrp from "../Experimental/UserHistoryGrp";
import ConceptsLearnedGroup from "../UtilityFunctions/ConceptsLearnedGroup";
import UserGrpAttendence from "../UtilityFunctions/UserGrpAttendence"
// import ConceptsLearnedGroup from "../Experimental/ConceptsLearnedGroup";
// import UserGrpAttendence from "../Experimental/UserGrpAttendence";


import { SiMicrosoftexcel } from "react-icons/si";

import { CSVLink, } from "react-csv";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import LoaderUtility from "./LoaderUtility";
// import { Context } from "../ErrorWrappers/conetxt";
import { Context } from "../ErrorWrappers/conetxt";
import { useContext } from "react";


import "./SessionPage.scss";


export default function GroupProgressboard({ selecttrack ,selectedgroup}) {

  const [mainTab, setMainTab] = useState("user_details");

  const [globalRank, setGlobalRank] = useState([]);
  const [activeSince, setActiveSince] = useState(0);
  const [classTotalSession, setClassTotalSession] = useState(0);
  const [totalBadgePoints, setTotalBadgePoints] = useState(0);
  const [exCount, setExCount] = useState(0);
  const [badgePoints, setBadgePoints] = useState([]);
  const [excount, setExcount] = useState([]);
  const [attendence, setAttendence] = useState([]);
  const [attendloader, setAttendLoader] = useState(false);
  const [progressLoader, setProgressLoader] = useState(false);
  const [conceptloader, setConceptLoader] = useState(false);
  const [glbrankloader, setGlbRankLoader] = useState(false);
  const [respLength, setRespLen] = useState(0);
  const [activeUsersLoader, setActiveLoader] = useState(false);
  const [sorted3, setSorted3] = useState({ sorted: "ex_completed", reversed: true })



  let Api = new ApiConfig();
  let track = useContext(Context);

  let group = selectedgroup;



  // async function getUserResposneRecords(title, date1, date2, userid) {
  //   setResLoader(true);
  //   // console.log("api", (Api.addApiKey(`${Api.BaseURI}/getuserresponsesummary?usergroup=${sessionStorage.getItem('usergroup')}&title=${title}&date1=${date1}&date2=${date2}&&userid=${userid}`)));
  //   await axios
  //     .get(
  //       Api.addApiKey(
  //         `${
  //           Api.BaseURI
  //         }/getuserresponsesummary?usergroup=${sessionStorage.getItem(
  //           "usergroup"
  //         )}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}&track=${selecttrack}`
  //       )
  //     )
  //     .then((resp) => {
  //       if (resp.data.status === 200) {
  //         console.log(resp.data);

  //         setUserResponse(resp.data.result);
  //       }
  //       setResLoader(false);
  //     });

  //   // return userresponse
  // }

  // async function evalResponseSummary(title, date1, date2, userid) {
  //   setEvalLoader(true);
  //   // console.log("first", (Api.addApiKey(`${Api.BaseURI}/getevalresponsesummary?usergroup=${sessionStorage.getItem('usergroup')}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}`)));
  //   await axios
  //     .get(
  //       Api.addApiKey(
  //         `${
  //           Api.BaseURI
  //         }/getevalresponsesummary?usergroup=${sessionStorage.getItem(
  //           "usergroup"
  //         )}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}&track=${selecttrack}`
  //       )
  //     )
  //     .then((resp) => {
  //       if (resp.data.status === 200) {
  //         console.log("resp.data.result", resp.data.result);
  //         setEvalRespSummary(resp.data.result);
  //       }
  //       setEvalLoader(false);
  //     });
  // }

  // async function userSessionSummary(title, date1, date2, userid) {
  //   setActiveLoader(true);
  //   // let group = "JSM College"
  //   // let user = 'satyajit.tulpule@gmail.com'
  //   // console.log("first", (Api.addApiKey(`${Api.BaseURI}/getsessionsummary?usergroup=${group}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}`)));
  //   await axios
  //     .get(
  //       Api.addApiKey(
  //         `${Api.BaseURI}/getsessionsummary?usergroup=${sessionStorage.getItem(
  //           "usergroup"
  //         )}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}&track=${selecttrack}`
  //       )
  //     )
  //     .then((resp) => {
  //       if (resp.data.status === 200) {
  //         // filterColumns(resp.data.result);
  //         console.log("getsessionsummary", resp.data.result);
  //         // console.log("cols", cols)
  //         setSessionSummary(resp.data.result);
  //       }
  //       setActiveLoader(false);
  //     });
  // }

  // async function getDistinctUserResponse(title, date1, date2, userid) {
  //   setResLoader(true);
  //   // console.log(Api.addApiKey(`${Api.BaseURI}/distinctuserresponse?usergroup=${sessionStorage.getItem('usergroup')}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}`));
  //   await axios
  //     .get(
  //       Api.addApiKey(
  //         `${
  //           Api.BaseURI
  //         }/distinctuserresponse?usergroup=${sessionStorage.getItem(
  //           "usergroup"
  //         )}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}&track=${selecttrack}`
  //       )
  //     )
  //     .then((resp) => {
  //       if (resp.data.status === 200) {
  //         // console.log("getDistinctUserResponse", resp.data.result);
  //         setDistinctUserResponse(resp.data.result[0]);
  //       }
  //       setResLoader(false);
  //     });
  // }

  // async function getDistinctEvalResponse(title, date1, date2, userid) {
  //   setEvalLoader(true);
  //   // console.log(Api.addApiKey(`${Api.BaseURI}/distinctevalresponse?usergroup=${sessionStorage.getItem('usergroup')}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}`))
  //   await axios
  //     .get(
  //       Api.addApiKey(
  //         `${
  //           Api.BaseURI
  //         }/distinctevalresponse?usergroup=${sessionStorage.getItem(
  //           "usergroup"
  //         )}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}&track=${selecttrack}`
  //       )
  //     )
  //     .then((resp) => {
  //       if (resp.data.status === 200) {
  //         // console.log("getDistinctEvalResponse", resp.data.result)
  //         setDistinctEvalResp(resp.data.result[0]);
  //       }
  //       setEvalLoader(false);
  //     });
  // }

  // async function getDistinctSessionUsers(title, date1, date2, userid) {
  //   setActiveLoader(true);
  //   // console.log(Api.addApiKey(`${Api.BaseURI}/distinctsessionusers?usergroup=${sessionStorage.getItem('usergroup')}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}`))
  //   await axios
  //     .get(
  //       Api.addApiKey(
  //         `${
  //           Api.BaseURI
  //         }/distinctsessionusers?usergroup=${sessionStorage.getItem(
  //           "usergroup"
  //         )}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}&track=${selecttrack}`
  //       )
  //     )
  //     .then((resp) => {
  //       if (resp.data.status === 200) {
  //         // console.log("getDistinctSessionUsers", resp.data.result[0].user_count);
  //         setDistinctSessionUsers(resp.data.result[0].user_count);
  //       }
  //       setActiveLoader(false);
  //     });
  // }

  async function getUserSummaryInfo() {

    setGlbRankLoader(true);
    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI}/userglobalrankgroup?usergroup=${group}&track=${selecttrack}`
        )
      )
      .then((resp) => {
        console.log("userglobalrankgroup", resp.data)
        setGlobalRank(resp.data.map(item => {
          return {
            First_name: item.First_name,
            Last_name: item.Last_name,
            User_name: item.Email,
            Exercises_completed: item.Exercises_completed,
            Percentile: item.Percentile,
            Last_evaluation_percentage: item.Last_evaluation_percentage,
            Class_rank: item.Class_rank,
            Global_rank: item.Global_rank
          }
        }));
      });
    setGlbRankLoader(false);
  }

  async function getClasssProgresSummary() {
    // console.log(selecttrack)
    setActiveLoader(true);
    // console.log(Api.addApiKey(`${Api.BaseURI}/userclassprogress?usergroup=${group}&track=${selecttrack}`))
    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI}/userclassprogress?usergroup=${group}&track=${selecttrack}`
        )
      )
      .then((resp) => {
        // console.log("getClasssProgresSummary", resp);
        // console.log("getClasssProgresSummary", resp.data);
        // setColumnsData(Object.keys(resp.data[0]))
        // setRespClsLen(resp.data.length)
        // console.log("first")
        setActiveSince(resp.data.result[0].activesince);
        // console.log("resp.",resp)
        setClassTotalSession(() => {
          if (
            resp.data.result1[0].activeusers === undefined ||
            resp.data.result1[0].activeusers === null ||
            resp.data.result1[0].activeusers === ""
          ) {
            return 0;
          } else {
            return resp.data.result1[0].activeusers;
          }
        });
        setTotalBadgePoints(() => {
          if (
            resp.data.result[0].totalbadges === null ||
            resp.data.result[0].totalbadges === undefined ||
            resp.data.result[0].totalbadges === ""
          ) {
            return 0;
          } else {
            return resp.data.result[0].totalbadges;
          }
        });
        setExCount(() => {
          if (
            resp.data.result[0].totalexid === null ||
            resp.data.result[0].totalexid === undefined ||
            resp.data.result[0].totalexid === ""
          ) {
            return 0;
          } else {
            return resp.data.result[0].totalexid;
          }
        });
      });
    setActiveLoader(false);
  }

  function getWeekNumber(date) {
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
    return weekNo;
  }
  function weekbyweek(week1) {
    let today = new Date();
    // currentDate.setMonth(3)
    // currentDate.setDate(27);
    // console.log("current date was",currentDate)

    let check = [];
    let week3 = [];
    let check3 = [];
    let finalarr = [];
    let finalarr1 = [];
    let attendencearr = [];
    // console.log("current data is", currentDate)
    // var year1 = new Date(currentDate.getFullYear(), 0, 1);
    // var days = Math.floor((currentDate - year1) / (24 * 60 * 60 * 1000));
    for (let i = 0; i < 5; i++) {
      var week = getWeekNumber(today);
      check.push({
        week: week.toString(),
        attendence: "0",
        exercises: "0",
        badgepoints: "0",
      });
      today.setDate(today.getDate() - 7);
    }
    check = check.reverse();
    // console.log("final week is", check);
    // console.log("incoming week", week1)
    for (let k = 0; k < week1.length; k++) {
      week3.push(week1[k].week);
    }

    // console.log("week3", week3);
    for (let r = 0; r < check.length; r++) {
      check3.push(check[r].week);
    }
    // console.log("check3", check3)

    for (let j = 0; j < week1.length; j++) {
      // week3.push(week1[j].week)
      if (check3.includes(week3[j])) {
        check[check3.indexOf(week3[j])] = week1[j];
      }
    }
    // console.log(check)

    for (let i = 0; i < check.length; i++) {
      // console.log("dfdf", check[i].exercises)
      finalarr.push(check[i].exercises);
      finalarr1.push(check[i].badgepoints);
      attendencearr.push(check[i].attendence);
    }

    // console.log("finalarr", finalarr)
    // console.log("finalarr1", finalarr1)
    // console.log("attendencearr", attendencearr)
    setExcount(finalarr);
    setBadgePoints(finalarr1);
    setAttendence(attendencearr);
  }


  const fetchWeekByProgress = async () => {
    // console.log(Api.addApiKey(`${Api.BaseURI}/weekbygrouprogress?usergroup=${user_group}&track=${language}`));
    // let group = 'JSM College'
    setAttendLoader(true);
    setProgressLoader(true);
    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI}/weekbygrouprogress?usergroup=${group}&track=${selecttrack}`
        )
      )
      .then((resp) => {
        // console.log("exercisehistorygroup", resp.data);
        setRespLen(resp.data.length);
        weekbyweek(resp.data);
      });
    setProgressLoader(false);
    setAttendLoader(false);
  };

  useEffect(() => {

    if (selecttrack !== '') {
      getUserSummaryInfo();
      getClasssProgresSummary();
      fetchWeekByProgress();
    }


  }, [selecttrack,selectedgroup]);

  function date_helper(date) {
    const d = new Date(date);
    return (
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear()
    );
  }


  function render3() {
    if (sorted3.reversed) {
      // console.log("sorted1.reversed ", sorted3.reversed)
      return <FaArrowDown />;
    } else {
      return <FaArrowUp />;
    }
  }

  function getClasssProgres() {
    return (
      <>
        <div className="badge-list learning">
          <div className="badge-list-item">
            <div className="badge-list-item_title">Skill building</div>
            <div className="badge-list-item_data">{classTotalSession} {classTotalSession === "1" ? "session" : "sessions"}</div>
          </div>
          <div className="badge-list-item">
            <div className="badge-list-item_title">Exercises completed</div>
            <div className="badge-list-item_data">{exCount}</div>
          </div>
          <div className="badge-list-item">
            <div className="badge-list-item_title">Badge points earned</div>
            <div className="badge-list-item_data">{totalBadgePoints}</div>
          </div>
          <div className="badge-list-item">
            <div className="badge-list-item_title">Active since</div>
            <div className="badge-list-item_data">
              {activeSince === null ||
                activeSince === undefined ||
                activeSince === "" || activeSince == 0
                ? "N/A"
                : date_helper(activeSince)}
            </div>
          </div>
        </div>
      </>
    );
  }

  const sortID = () => {
    setSorted3({ sorted: "id", reversed: !sorted3.reversed });
    const rankCopy = [...globalRank];
    rankCopy.sort((a, b) => {
      if (sorted3.reversed) {
        return a.id - b.id;
      }
      return b.id - a.id;
    });
    setGlobalRank(rankCopy);
  };
  const sortEmail = () => {
    setSorted3({ sorted: "email", reversed: !sorted3.reversed });
    const rankCopy = [...globalRank];
    rankCopy.sort((a, b) => {
      const emailA = a.User_name;
      const emailB = b.User_name;

      if (sorted3.reversed) {
        return emailA.localeCompare(emailB);
      }
      return emailB.localeCompare(emailA);

      // console.log(emailA)
      // console.log(emailB)
    });
    setGlobalRank(rankCopy);
  };
  const sortFirstName = () => {
    setSorted3({ sorted: "first", reversed: !sorted3.reversed });
    const rankCopy = [...globalRank];
    rankCopy.sort((a, b) => {
      const firstA = a.First_name;
      const firstB = b.First_name;

      if (sorted3.reversed) {
        return firstA.localeCompare(firstB);
      }
      return firstB.localeCompare(firstA);

      // console.log(emailA)
      // console.log(emailB)
    });
    setGlobalRank(rankCopy);
  };
  const sortLastName = () => {
    setSorted3({ sorted: "last", reversed: !sorted3.reversed });
    const rankCopy = [...globalRank];
    rankCopy.sort((a, b) => {
      const lastA = a.Last_name;
      const lastB = b.Last_name;

      if (sorted3.reversed) {
        return lastA.localeCompare(lastB);
      }
      return lastB.localeCompare(lastA);

      // console.log(emailA)
      // console.log(emailB)
    });
    setGlobalRank(rankCopy);
  };
  const sortBadges = () => {
    setSorted3({ sorted: "ex_completed", reversed: !sorted3.reversed });
    const rankCopy = [...globalRank];
    rankCopy.sort((a, b) => {
      if (sorted3.reversed) {
        return a.Exercises_completed - b.Exercises_completed;
      }
      return b.Exercises_completed - a.Exercises_completed;

      // console.log(emailA)
      // console.log(emailB)
    });
    setGlobalRank(rankCopy);
  };
  const sortPercentile = () => {
    setSorted3({ sorted: "percentile", reversed: !sorted3.reversed });
    const rankCopy = [...globalRank];
    rankCopy.sort((a, b) => {
      if (sorted3.reversed) {
        return a.Percentile - b.Percentile;
      }

      return b.Percentile - a.Percentile;
      // console.log(emailA)
      // console.log(emailB)
    });
    setGlobalRank(rankCopy);
  };
  const sortGlobal = () => {
    setSorted3({ sorted: "global", reversed: !sorted3.reversed });
    const rankCopy = [...globalRank];
    rankCopy.sort((a, b) => {
      if (sorted3.reversed) {
        return a.Global_rank - b.Global_rank;
      }
      return b.Global_rank - a.Global_rank;
      // console.log(emailA)
      // console.log(emailB)
    });
    setGlobalRank(rankCopy);
  };
  const sortLocal = () => {
    setSorted3({ sorted: "local", reversed: !sorted3.reversed });
    const rankCopy = [...globalRank];
    rankCopy.sort((a, b) => {
      if (sorted3.reversed) {
        return a.Class_rank - b.Class_rank;
      }

      return b.Class_rank - a.Class_rank;
      // console.log(emailA)
      // console.log(emailB)
    });
    setGlobalRank(rankCopy);
  };
  const sortPerc = () => {
    setSorted3({ sorted: "eval_perc", reversed: !sorted3.reversed });
    const rankCopy = [...globalRank];
    rankCopy.sort((a, b) => {
      if (sorted3.reversed) {
        return a.Last_evaluation_percentage - b.Last_evaluation_percentage;
      }

      return b.Last_evaluation_percentage - a.Last_evaluation_percentage;
      // console.log(emailA)
      // console.log(emailB)
    });
    setGlobalRank(rankCopy);
  };

  function userSummary() {
    // settablename("userSummary")
    return (
      <>
        <div className="pyui_user_summary-table-inner table-scroll">
          {glbrankloader ? (
            <LoaderUtility loading={glbrankloader} />
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className="table-box-heading">
                        <span>#</span>
                      </div>
                      {/* <span>{sorted.sorted === "id" ? renderArrow() : null}</span> */}
                    </th>
                    <th onClick={sortFirstName}>
                      <div className="table-box-heading">
                        {/* <div className='box-heading'> */}
                        <span className="badge-name">First name</span>
                        <div>
                          {sorted3.sorted === "first" ? render3() : null}
                        </div>

                        {/* </div> */}
                      </div>
                      {/* <div >First Name</div> */}
                      {/* <div>{sorted.sorted === "first" ? render3() : null}</div> */}
                    </th>
                    <th onClick={sortLastName}>
                      <div className="table-box-heading">
                        {/* <div className='box-heading'> */}
                        <span className="badge-name">Last name</span>
                        <div>
                          {sorted3.sorted === "last" ? render3() : null}
                        </div>
                        {/* </div> */}
                      </div>
                    </th>
                    <th onClick={sortEmail}>
                      <div className="table-box-heading">
                        {/* <div className='box-heading'> */}
                        <span className="badge-name">User name</span>
                        <div>
                          {sorted3.sorted === "email" ? render3() : null}
                        </div>
                        {/* </div> */}
                      </div>
                    </th>
                    <th onClick={sortBadges}>
                      <div className="table-box-heading">
                        {/* <div className='box-heading'> */}
                        <span className="badge-name">Exercises completed</span>
                        <div>
                          {sorted3.sorted === "ex_completed" ? render3() : null}
                        </div>
                        {/* </div> */}
                      </div>
                    </th>
                    <th onClick={sortPercentile}>
                      <div className="table-box-heading">
                        {/* <div className='box-heading'> */}
                        <span className="badge-name">Percentile</span>
                        <div>
                          {sorted3.sorted === "percentile" ? render3() : null}
                          {/* </div> */}
                        </div>
                      </div>
                    </th>
                    <th onClick={sortPerc}>
                      <div className="table-box-heading">
                        {/* <div className='box-heading'> */}
                        <span className="badge-name">Last evaluation %</span>
                        <div>
                          {sorted3.sorted === "eval_perc" ? render3() : null}
                        </div>
                        {/* </div> */}
                      </div>
                    </th>
                    <th onClick={sortLocal}>
                      <div className="table-box-heading">
                        {/* <div className='box-heading'> */}
                        <span className="badge-name">Class rank</span>
                        <div>
                          {sorted3.sorted === "local" ? render3() : null}
                        </div>
                        {/* </div> */}
                      </div>
                    </th>
                    <th onClick={sortGlobal}>
                      <div className="table-box-heading">
                        {/* <div className='box-heading'> */}
                        <span className="badge-name">Global rank</span>
                        <div>
                          {sorted3.sorted === "global" ? render3() : null}
                        </div>
                        {/* </div> */}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {globalRank.map((items, index) => (
                    <tr>
                      <td>{items.id}</td>
                      <td>{items.First_name}</td>
                      <td>{items.Last_name}</td>
                      <td>{items.User_name}</td>
                      <td>{items.Exercises_completed}</td>
                      <td>{items.Percentile.toFixed(2)}</td>
                      <td>{items.Last_evaluation_percentage.toFixed(2)} %</td>
                      <td>{items.Class_rank}</td>
                      <td>{items.Global_rank}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </>
    );
  }












  return (
    <>
      <section className="pyui_main-content-section page-progressboard with-alternate-tab">
        {/* <Breadcrumbs /> */}
        <section className="pyui_main-container-section-content">
          <h2 className="page-title">Team Learnings</h2>
          <div className="pyui_main-container-section-topbar">
            <div className="pyui_main-container-section-topbar-tabs">
              {/* <Tabs /> */}
              {/* <div className="session_title">Team Learnings</div> */}
            </div>
            <div className="pyui_main-container-section-topbar-option">
              {/* <div className="pyui_quiz-widget">
                <ul className="pyui_quiz-content">
                  <li className="pyui_quiz-content--title">Monthly Quizzes:</li>
                  <li className="pyui_quiz-status complete">
                    Complete <span className="pyui_quiz-status-count">10</span>
                  </li>
                  <li className="pyui_quiz-status pending">
                    Pending <span className="pyui_quiz-status-count">10</span>
                  </li>
                  <li className="pyui_quiz-status action">
                    <button className="link-button small"> more.. </button>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          {getClasssProgres()}
          <div className="group_progress">
            {/* <span className='group_progress-user'> */}
            <span className="group_progress-userexgrp">
              {progressLoader ? (
                <LoaderUtility loading={progressLoader} />
              ) : (
                <UserHistoryGrp
                  excount={excount}
                  badgePoints={badgePoints}
                  respLength={respLength}
                />
              )}
            </span>
            <span className="group_progress-userattend">
              {attendloader ? (
                <LoaderUtility loading={attendloader} />
              ) : (
                <UserGrpAttendence
                  attendence={attendence}
                  respLength={respLength}
                />
              )}
            </span>
            {/* </span> */}
            <span className="group_progress-concepts">
              <ConceptsLearnedGroup
                setConceptLoader={setConceptLoader}
                conceptloader={conceptloader}
                selecttrack={selecttrack}
                selectedgroup={selectedgroup}
              />
            </span>
          </div>
          <section className="pyui_user_summary">
            <nav className="pyui_tabbar_streched">
              <span
                className={`tab-item ${mainTab === "user_details" ? "selected" : ""
                  }`}
                onClick={() => {
                  setMainTab("user_details");
                }}
              >
                Summary
              </span>
            </nav>


            {mainTab === "user_details" && (
              <>
                {/* <h3 >{setEvalSumTitle(distinctevalresp)}</h3> */}
                <div className="pyui_user_summary-topbar">
                  <span>
                    {globalRank.length === 0
                      ? <></>
                      : <p className='details-title'>Total {globalRank.length} users active in the {selecttrack} track </p>}
                  </span>
                  <CSVLink
                    className={`icon-button secondary small ${globalRank.length === 0 ? "disabled " : ""
                      }`}
                    title="Download as csv"
                    data={globalRank}
                    filename={"User_summary.csv"}
                  >
                    {/* <button className={globalRank.length === 0 ? 'primary-button disabled csv-linkbtn' : 'primary-button csv-linkbtn'} disabled={globalRank.length === 0}> */}
                    <SiMicrosoftexcel size={16} />
                    {/* </button> */}
                  </CSVLink>
                </div>
                <div className="pyui_user_summary-table">{userSummary()}</div>
              </>
            )}
          </section>
        </section>
      </section>

    </>
  );
}
