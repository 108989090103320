import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import ApiConfig from "../../ApiConfig";
import ReactApexChart from "react-apexcharts";
import Switch from "react-switch";
import "./exprtl.scss";
import "./userhistorygrp.scss";
import LoaderUtility from "../Session/LoaderUtility";
import { Context } from "../ErrorWrappers/conetxt";
import { useContext } from "react";

const ConceptsLearnedGroup = ({
  setConceptLoader,
  conceptloader,
  selecttrack,
  selectedgroup
}) => {
  const [subcategoridarr, setsubcategoridarr] = useState([]);
  const [total_excomlpeted, settotalexcomlpeted] = useState([]);
  const [checked, setChecked] = useState(false);
  let Api = new ApiConfig();
  // let language = sessionStorage.getItem("language");

  let track = useContext(Context);
  let user_group = selectedgroup;
  // let user_group = 'JSM College';

  const fetchData = async () => {
    let subctegorytemparr = [];
    let tot_excomlptemparr = [];

    if (checked === true) {
      setConceptLoader(true);
      await axios
        .get(
          Api.addApiKey(
            `${Api.BaseURI}/usercategorygroup?usergroup=${user_group}&track=${selecttrack}&flag=${checked}`
          )
        )
        .then((resp) => {
          console.log(resp.data);
          // console.log(Api.addApiKey(`${Api.BaseURI}/usercategoryfeatures?userid=${username}&track=${language}`));
          for (let i = 0; i < resp.data.result.length; i++) {
            subctegorytemparr.push(resp.data.result[i].subcategoryid);
            tot_excomlptemparr.push(resp.data.result[i].total);
          }
          setsubcategoridarr(subctegorytemparr);
          settotalexcomlpeted(tot_excomlptemparr);
        });
      setConceptLoader(false);
    } else if (checked === false) {
      setConceptLoader(true);
      await axios
        .get(
          Api.addApiKey(
            `${Api.BaseURI}/usercategorygroup?usergroup=${user_group}&track=${selecttrack}&flag=${checked}`
          )
        )
        .then((resp) => {
          console.log(resp.data);
          // console.log(Api.addApiKey(`${Api.BaseURI}/usercategoryfeatures?userid=${username}&track=${language}`));
          for (let i = 0; i < resp.data.result.length; i++) {
            subctegorytemparr.push(resp.data.result[i].category);
            tot_excomlptemparr.push(resp.data.result[i].total);
          }
          setsubcategoridarr(subctegorytemparr);
          settotalexcomlpeted(tot_excomlptemparr);
        });
      setConceptLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selecttrack, checked]);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  function detailSwitch() {
    return (
      <>
        <Switch
          onChange={handleChange}
          checked={checked}
          className="react-switch"
          height={16}
          width={36}
          // onColor="#86d3ff"
          // onHandleColor="#2693e6"
          // width={38}
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 16,
                color: "yellow",
                paddingRight: 1,
              }}
            >
              +
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 16,
                color: "yellow",
                paddingRight: 2,
              }}
            >
              -
            </div>
          }
          handleDiameter={20}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        />
      </>
    );
  }

  function renderChips() {
    return (
      <>
        <div className="progress-block-wrapper">
          <div className="progress-block-title-withSwitch">
            <span className="progress-block-title">Concepts learned</span>
            <span className="progress-block-switch">
              {checked ? "Show less " : "Show more "}
              {detailSwitch()}
            </span>
          </div>
          <div className="progress-block-content">{displaychips()}</div>
          <div className="progress-block-footer">
            Exercises completed per concept
          </div>
        </div>
      </>
    );
  }

  function displaychips() {
    if (conceptloader) {
      return <LoaderUtility loading={conceptloader} />;
    } else {
      if (subcategoridarr.length === 0) {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No concepts learned
          </span>
        );
      } else {
        return (
          <>
            <ul className="chip-list">
              {subcategoridarr.map((items, i) => (
                <>
                  <li className="chip-items">
                    {items}
                    <span className="item-excount">
                      {total_excomlpeted[i].toString().length === 1
                        ? ("0" + total_excomlpeted[i]).slice(-2)
                        : total_excomlpeted[i]}
                    </span>
                  </li>
                </>
              ))}
            </ul>
          </>
        );
      }
    }
  }

  const series1 = [
    {
      name: "Exercises completed",
      data: total_excomlpeted,
    },
  ];
  const options1 = {
    colors: ["#FF7F50"],
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },

    xaxis: {
      categories: subcategoridarr,
      title: {
        text: "Exercises",
      },
      // tickPlacement: 'between',
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 2,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetX: 20,

      style: {
        fontSize: "12px",
        colors: ["#0000FF"],
      },
    },

    title: {
      text: "Concepts Learnt",
      // align: 'center',
      offsetY: 10,
      style: {
        fontSize: "13px",
        colors: ["#304958"],
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
      title: {
        text: "Concepts",
      },
    },
  };
  return (
    <>
      {/* {
        subcategoridarr.length === 0
          ? "No data available"
          : <ReactApexChart id='chart' options={options1} series={series1} type="bar" height={400} width={400} />
      } */}
      {renderChips()}
    </>
  );
};

export default ConceptsLearnedGroup;
