import React, { useEffect, useRef, useState } from 'react';
import { date_helper } from '../UtilityFunctions/getCurrentWeek copy';
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { FiRefreshCcw } from 'react-icons/fi'
import ApiConfig from '../../ApiConfig';
import axios from 'axios';
import { LoaderUtility1 } from './LoaderUtility';
import { getValueFromSession } from '../UtilityFunctions/getSetValueSession';
import { SiMicrosoftexcel } from 'react-icons/si';
import { CSVLink } from 'react-csv';

const GroupQuizHistory = ({ selecttrack ,selectedgroup}) => {

  const [quizweekly, setquizweekly] = useState([]);
  const [quizmonthly, setquizmonthly] = useState([]);
  const [loading, setloading] = useState(false);
  const [sorted1, setSorted1] = useState({ sorted: "Date", reversed: true })
  const [sorted2, setSorted2] = useState({ sorted: "Date", reversed: true })
  const [option, setOption] = useState("30days");
  const [selected1, setSelected1] = useState("Last 30 days");
  const [isActive1, setIsActive1] = useState(false);
  const [title, setTitle] = useState(true);
  // console.log("selecttrack", selecttrack)
  // console.log("quizmonthly", quizmonthly)

  // let username = sessionStorage.getItem("username");
  let username = getValueFromSession('username');
  let usergroup = selectedgroup
  let Api = new ApiConfig();



  function render1() {
    if (sorted2.reversed) {
      // console.log("sorted1.reversed ", sorted1.reversed)
      return <FaArrowDown />;
    } else {
      return <FaArrowUp />;
    }
  }

  function render() {
    if (sorted1.reversed) {
      // console.log("sorted1.reversed ", sorted1.reversed)
      return <FaArrowDown />;
    } else {
      return <FaArrowUp />;
    }
  }


  useEffect(() => {

    getQuizHistory("30days");

  }, [selecttrack,selectedgroup]);


  async function getQuizHistory(title) {

    // let weeklyq = []
    // let monthlyq = []
    setloading(true);
    let quizHistoryResp = await axios.get(Api.addApiKey(`${Api.BaseURI}/quizhistoryGroup?usergroup=${usergroup}&track=${selecttrack}&title=${title}`));

    // weekly
    setquizweekly(quizHistoryResp.data.filter(resp => resp.module_name.includes('_quiz_')).map(item => {
      // console.log("item", item)
      return {
        User_name: item.userid,
        Quiz_name: item.lable,
        Score: item.score,
        Total_marks: item.total_marks,
        Date_completed: date_helper(item.end_time)
      }
    }));

    // monthly
    setquizmonthly(quizHistoryResp.data.filter(resp => resp.module_name.includes('month')).map(item => {
      return {
        User_name: item.userid,
        Quiz_name: item.lable,
        Score: item.score,
        Total_marks: item.total_marks,
        Date_completed: date_helper(item.end_time)
      }
    }));
    setloading(false);
    console.log("quizweekly", quizweekly)
    console.log("quizmonthly", quizmonthly)
  }
  const sortQuizname = (quizmonthly1, variable) => {
    if (variable == 'month') {
      setSorted2({ sorted: "Quiz", reversed: !sorted2.reversed });
    }
    else {
      setSorted1({ sorted: "Quiz", reversed: !sorted1.reversed });

    } const activeusers = [...quizmonthly1];
    activeusers.sort((a, b) => {
      const emailA = a.User_name;
      const emailB = b.User_name;

      if (variable == 'month') {
        if (sorted2.reversed) {
          return emailA.localeCompare(emailB);
        }
        return emailB.localeCompare(emailA);
      } else {
        if (sorted1.reversed) {
          return emailA.localeCompare(emailB);
        }
        return emailB.localeCompare(emailA);
      }


      // console.log(emailA)
      // console.log(emailB)
    });
    // setGlobalRank(rankCopy);
    if (variable == 'month') {
      setquizmonthly(activeusers);
    }
    else {
      setquizweekly(activeusers)
    }
  };
  const SortDate = (quizmonthly1, variable) => {
    if (variable == 'month') {
      setSorted2({ sorted: "Date", reversed: !sorted2.reversed });
    }
    else {
      setSorted1({ sorted: "Date", reversed: !sorted1.reversed });

    }
    function convertDate(date) {
      var parts = date.split("-");
      return new Date(parts[2], parts[1] - 1, parts[0]);
  }
    const rankCopy =  [...quizmonthly1].sort((a, b) => {
      if(variable=='month'){

      
      if (sorted2.reversed) {
        return convertDate(a.Date_completed) - convertDate(b.Date_completed);
      }
      return convertDate(b.Date_completed )- convertDate(a.Date_completed);
    }
    else{
      if (sorted1.reversed) {
        return convertDate(a.Date_completed) - convertDate(b.Date_completed);
      }
      return convertDate(b.Date_completed )- convertDate(a.Date_completed);
    }
   
    });

    if (variable == 'month') {
      setquizmonthly(rankCopy)
    }
    else {
      setquizweekly(rankCopy)
    }

  };

  const sortScore = (quizmonthly1, variable) => {
    // setSorted1({ sorted: "Score", reversed: !sorted1.reversed });
    if (variable == 'month') {
      setSorted2({ sorted: "Score", reversed: !sorted2.reversed });
    }
    else {
      setSorted1({ sorted: "Score", reversed: !sorted1.reversed });

    }
    const rankCopy = [...quizmonthly1];
    rankCopy.sort((a, b) => {
      if (variable == 'month') {
        if (sorted2.reversed) {
          return a.Score - b.Score;
        }
        return b.Score - a.Score;
      } else {
        if (sorted1.reversed) {
          return a.Score - b.Score;
        }
        return b.Score - a.Score;
      }

      // console.log(emailA)
      // console.log(emailB)
    });

    if (variable == 'month') {
      setquizmonthly(rankCopy);
    }
    else {
      setquizweekly(rankCopy)
    }

  };
  const sortTotalMarks = (quizmonthly1, variable) => {
    // setSorted1({ sorted: "Total", reversed: !sorted1.reversed });
    if (variable == 'month') {
      setSorted2({ sorted: "Total", reversed: !sorted2.reversed });
    }
    else {
      setSorted1({ sorted: "Total", reversed: !sorted1.reversed });

    }

    const rankCopy = [...quizmonthly1];
    rankCopy.sort((a, b) => {
      if (variable == 'month') {
        if (sorted2.reversed) {
          return a.Total_marks - b.Total_marks;
        } else {
          return b.Total_marks - a.Total_marks;
        }
      } else {
        if (sorted1.reversed) {
          return a.Total_marks - b.Total_marks;
        } else {
          return b.Total_marks - a.Total_marks;
        }
      }


      // console.log(emailA)
      // console.log(emailB)
    });
    if (variable == 'month') {
      setquizmonthly(rankCopy);
    }
    else {
      setquizweekly(rankCopy)
    }
  };

  // console.log(loading);
  let menuRef1 = useRef();
  // console.log("menuRef", menuRef)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef1.current && !menuRef1.current.contains(event.target)) {
        setIsActive1(false);
        // setIsDateActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef1]);


  function setDropDown() {
    return (
      <>
        <div ref={menuRef1} className="pyui_session">
          <div
            className={`pyui_session-inner ${isActive1 ? "expanded" : ""}`}
            onClick={(e) => setIsActive1(!isActive1)}
          >
            <span>{selected1}</span>
          </div>
          {isActive1 && (
            <ul className="pyui_session-list">
              {/* <li className='pyui_session-list-item'
								onClick={(e) => {
									setSelected1(e.target.textContent)

									evalResponseSummary('session', '', '', sessionStorage.getItem('username'));
									getUserResposneRecords('session', '', '', sessionStorage.getItem('username'));
									userSessionSummary('session', '', '', sessionStorage.getItem('username'));
									getDistinctUserResponse('session', '', '', sessionStorage.getItem('username'));
									getDistinctEvalResponse('session', '', '', sessionStorage.getItem('username'));
									getDistinctSessionUsers('session', '', '', sessionStorage.getItem('username'));
									setIsActive1(false);
									setOption('Latest session');
									// getSessionInfo()
									// getUserEvalResp()
									// getUserSessionResp()
									setTitle(true)
								}}
							>
								Latest session
							</li> */}
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  setSelected1(e.target.textContent);
                  getQuizHistory("today");

                  setOption("today");
                  setIsActive1(false);
                  setTitle(false)
                }}
              >
                Today
              </li>
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('7days')
                  setSelected1(e.target.textContent);

                  getQuizHistory("7days");
                  setOption("7days");
                  setIsActive1(false);
                  setTitle(false)
                }}
              >
                Last 7 days
              </li>
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('15days');
                  setSelected1(e.target.textContent);

                  getQuizHistory("15days");
                  setOption("15days");
                  setIsActive1(false);
                  setTitle(false)
                }}
              >
                Last 15 days
              </li>
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('30days');
                  setSelected1(e.target.textContent);
                  getQuizHistory("30days");

                  setOption("30days");
                  setIsActive1(false);
                  setTitle(false);
                }}
              >
                Last 30 days
              </li>
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('30days');
                  setSelected1(e.target.textContent);
                  getQuizHistory("90days");

                  setOption("90days");
                  setIsActive1(false);
                  setTitle(false);
                }}
              >
                Last 90 days
              </li>
              {/* <li className='pyui_session-list-item'
								onClick={(e) => {
									// setTitle('30days');
									setSelected1(e.target.textContent);

									evalResponseSummary('180days')

									getUserResposneRecords('180days');
									getQuizHistory('180days');
									getDistinctUserResponse('180days');
									getDistinctEvalResponse('180days');
									getDistinctSessionUsers('180days');
									setOption('180days');
									setIsActive1(false);
									setTitle(false);
								}}
							>
								Last 180 days
							</li> */}
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('30days');
                  setSelected1(e.target.textContent);
                  getQuizHistory("all");

                  setOption("all");
                  setIsActive1(false);
                  setTitle(false);
                }}
              >
                Entire period
              </li>
              {/* <li className={`pyui_session-list-item-date ${isDateActive ? "expand" : ""}`}
								onClick={(e) => {
									setIsDateActive(!isDateActive);
									// setTitle('custom');
									// getUserResposneRecords()
									setSelected1(e.target.textContent);
									setTitle(false);
								}}
							>
								<span className='date-picker-title'>Custom date range</span>
							</li>
							{isDateActive && (
								<li className='pyui_session-list-date'>
									<div className='custom-date'>
										<input className='date-input-field' min="2020-01-01" max={dateFormat()} value={date1} onChange={e => setDate1(e.target.value)} type="date" />
										to
										<input className='date-input-field' min="2020-01-01" max={dateFormat()} value={date2} onChange={e => {
											setDate2(e.target.value);
										}} type="date" />
										<button className='primary-button' onClick={() => {
											evalResponseSummary('custom', date1, date2);
											getUserResposneRecords('custom', date1, date2);
											userSessionSummary('custom', date1, date2);
											getDistinctUserResponse('custom', date1, date2);
											getDistinctEvalResponse('custom', date1, date2);
											getDistinctSessionUsers('custom', date1, date2);
											setOption('custom');
											setIsActive1(false);
											setIsDateActive(!isDateActive);
											// setTitle(false);
										}}>Get details</button>
									</div>
								</li>
							)} */}
            </ul>
          )}
        </div>
      </>
    );
  }

  function tableWeeklyQuizHistory() {
    return (
      <>
        <div className='table-box1'>
          <table>
            <thead>
              <tr>
                <th>User name</th>
                <th onClick={(e) => { sortQuizname(quizweekly, 'week') }}>
                  <div className="table-box-heading">
                    <span className="badge-name">Quiz name</span>
                    <div>{sorted1.sorted === "Quiz" ? render() : null}</div>
                  </div>
                </th>
                <th onClick={(e) => { sortScore(quizweekly, 'week') }}>
                  <div className="table-box-heading">
                    <span className="badge-name">Score</span>
                    <div>{sorted1.sorted === "Score" ? render() : null}</div>
                  </div>
                </th>
                <th onClick={(e) => { sortTotalMarks(quizweekly, 'week') }}>
                  <div className="table-box-heading">
                    <span className="badge-name">Total marks</span>
                    <div>{sorted1.sorted === "Total" ? render() : null}</div>
                  </div>
                </th>
                <th onClick={(e) => { SortDate(quizweekly, 'week') }}>
                  <div className="table-box-heading">
                    <span className="badge-name">Date completed</span>
                    <div>{sorted1.sorted === "Date" ? render() : null}</div>
                  </div>
                </th>
              </tr>
            </thead>
            {quizweekly.length === 0
              ? (
                <tbody>
                  <td colspan="5">
                    <div className="pyyi_table-zerostate">
                      No weekly quizzes completed!
                    </div>
                  </td>
                </tbody>
              ) : (
                <>
                  {quizweekly.map((items, i) => (
                    <tbody>
                      <tr>
                        <td>{items.User_name}</td>
                        <td>{items.Quiz_name}</td>
                        <td>{items.Score}</td>
                        <td>{items.Total_marks}</td>
                        <td>{items.Date_completed}</td>
                      </tr>
                    </tbody>
                  ))}
                </>
              )}
          </table>
        </div>

      </>
    )
  }

  function tableMonthlyQuizHistory() {
    return (
      <>
        <div className='table-box1'>
          <table>
            <thead>
              <tr>
                <th>User name</th>
                <th onClick={(e) => { sortQuizname(quizmonthly, 'month') }}>
                  <div className="table-box-heading">
                    <span className="badge-name">Quiz name</span>
                    <div>{sorted2.sorted === "Quiz" ? render1() : null}</div>
                  </div>
                </th>
                <th onClick={(e) => { sortScore(quizmonthly, 'month') }}>
                  <div className="table-box-heading">
                    <span className="badge-name">Score</span>
                    <div>{sorted2.sorted === "Score" ? render1() : null}</div>
                  </div>
                </th>
                <th onClick={(e) => { sortTotalMarks(quizmonthly, 'month') }}>
                  <div className="table-box-heading">
                    <span className="badge-name">Total marks</span>
                    <div>{sorted2.sorted === "Total" ? render1() : null}</div>
                  </div>
                </th>
                <th onClick={(e) => { SortDate(quizmonthly, 'month') }}>
                  <div className="table-box-heading">
                    <span className="badge-name">Date completed</span>
                    <div>{sorted2.sorted === "Date" ? render1() : null}</div>
                  </div>
                </th>
              </tr>
            </thead>
            {quizmonthly.length === 0
              ? (
                <tbody>
                  <td colspan="5">
                    <div className="pyyi_table-zerostate">
                      No monthly quizzes completed!
                    </div>
                  </td>
                </tbody>
              ) : (
                <>
                  {quizmonthly.map((items, i) => (
                    <tbody>
                      <tr>
                        <td>{items.User_name}</td>
                        <td>{items.Quiz_name}</td>
                        <td>{items.Score}</td>
                        <td>{items.Total_marks}</td>
                        <td>{items.Date_completed}</td>
                      </tr>
                    </tbody>
                  ))}
                </>
              )}
          </table>
        </div>

      </>
    )
  }

  return (
    <>
      {loading ? (
        <>
          <LoaderUtility1 loading={loading} />
        </>
      ) : (
        <>
          <section className="pyui_user_summary">
            <div className="title"> Quiz History</div>
            <div className="select-interval">
              <span className="interval-title">Select interval</span>
              {setDropDown()}
              <button
                title="Refresh"
                className={
                  title === true
                    ? "icon-button small"
                    : "icon-button small disabled"
                }
              >
                <FiRefreshCcw size={16} />
              </button>
            </div>
            <section className="pyui_quizhistory">
              <div className='pyui_user_summary-table'>
                <div className="pyui_quizhistory-monthlyq">
                  <div className="pyui_user_summary-topbar">
                    <h4 className="pyui_quizhistory-section-header-title">
                      Monthly quizzes
                    </h4>
                    <button className={
                      quizmonthly.length === 0
                        ? "icon-button secondary disabled"
                        : "icon-button secondary"
                    }>
                      <CSVLink
                        title="Download as csv"
                        data={quizmonthly}
                        filename={"Monthly quizzes.csv"}
                      >
                        {/* <button className={sessionSummary.length === 0 && activeUsernames.length === 0 ? 'primary-button disabled' : 'primary-button'}
											disabled={sessionSummary.length === 0 && activeUsernames.length === 0}> */}
                        <SiMicrosoftexcel color="white" />
                        {/* </button> */}
                      </CSVLink>
                    </button>
                  </div>
                  {tableMonthlyQuizHistory()}
                </div>
              </div>
              {/* <hr className="pyui_hr-dividerLine-qhistory space-lg"></hr> */}
              <div className='pyui_user_summary-table'>
                <div className="pyui_quizhistory-weeklyq">
                  <div className="pyui_user_summary-topbar">
                    <h4 className="pyui_quizhistory-section-header-title">
                      Weekly quizzes
                    </h4>
                    <button className={
                      quizweekly.length === 0
                        ? "icon-button secondary disabled"
                        : "icon-button secondary"
                    }>
                      <CSVLink
                        title="Download as csv"
                        data={quizweekly}
                        filename={"Weekly quizzes.csv"}
                      >
                        {/* <button className={sessionSummary.length === 0 && activeUsernames.length === 0 ? 'primary-button disabled' : 'primary-button'}
											disabled={sessionSummary.length === 0 && activeUsernames.length === 0}> */}
                        <SiMicrosoftexcel color="white" />
                        {/* </button> */}
                      </CSVLink>
                    </button>
                  </div>
                  {tableWeeklyQuizHistory()}
                </div>
              </div>
            </section>
          </section>
        </>
      )}
    </>
  );
};

export default GroupQuizHistory;
