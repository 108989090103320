import { useState } from 'react';

// import MyJourney from './JourneyStatusTabs/ProgressBoard';
import GroupProgressboard from './GroupProgressBoard';
import GroupCertificates from './GroupCertificate'
import GroupQuizHistory from './GroupQuizhistory';
// import UserSession from './UserSession';
import Exercisehistory from './Exercisehistory';

// import Certificates from './JourneyStatusTabs/Certificates';
// import QuizHistory from './JourneyStatusTabs/QuizHistory';  

import './journeytabs.scss'
// import '../My Journey/JourneyTabs.scss'

const SessionTabs = ({ selecttrack, isLoading, selectedgroup,setIsLoading }) => {
  const [activeTab, setActive] = useState(() => {
    let curr = sessionStorage.getItem('jactivetab');
    return curr || "progress";
  });
  // console.log(activeTab)

  function setActiveTab(active) {
    switch (active) {
      case "progress":
        return (
          <GroupProgressboard selecttrack={selecttrack} setIsLoading={setIsLoading}
            isLoading={isLoading} selectedgroup={selectedgroup}/>
        );

      case "certificate":
        return (
          <GroupCertificates selecttrack={selecttrack} selectedgroup={selectedgroup} />
        );
      case "qhistory":
        return (
          <GroupQuizHistory selecttrack={selecttrack} selectedgroup={selectedgroup} />
        );
      case "exehistory":
        return (
          <Exercisehistory selecttrack={selecttrack} selectedgroup={selectedgroup} />
        );
      // case "usersession":
      //   return (
      //     <UserSession selecttrack={selecttrack} />
      //   );
      default:
        return (
          <GroupProgressboard />
        )
    }
  }

  const handleTab1 = () => {
    // update the state to tab1
    setActive("progress");
    sessionStorage.setItem("jactivetab", "progress")
  };

  const handleTab2 = () => {
    // update the state to tab1
    setActive("certificate");
    sessionStorage.setItem("jactivetab", "certificate")
  };
  const handleTab3 = () => {
    // update the state to tab1
    setActive("qhistory");
    sessionStorage.setItem("jactivetab", "qhistory")
  };
  const handleTab4 = () => {
    // update the state to tab1
    setActive("exehistory");
    sessionStorage.setItem("jactivetab", "qhistory")
  };
  // const handleTab5 = () => {
  //   // update the state to tab1
  //   setActive("usersession");
  //   sessionStorage.setItem("jactivetab", "usersession")
  // };



  return (
    <section className="pyui_journeytabs-container">
      <nav className="pyui_journeytabs-status-tabs">
        <ul className="pyui_journeytabs-status-tabs-jtlist">
          <li
            className={activeTab === "progress" ? "active" : ""}
            onClick={handleTab1}
          >
            <i id="available" class="fa-sharp fa-solid fa-circle"></i>
            Progress Board
            {/* <div className="count">{available.length}</div> */}
          </li>
          <li
            className={activeTab === "certificate" ? "active" : ""}
            onClick={handleTab2}
          >
            <i id="available" class="fa-sharp fa-solid fa-circle"></i>
            Certificates
            {/* <div className="count">{complete.length}</div> */}
          </li>
          <li
            className={activeTab === "qhistory" ? "active" : ""}
            onClick={handleTab3}
          >
            <i id="available" class="fa-sharp fa-solid fa-circle"></i>
            Quiz History
            {/* <div className="count">{complete.length}</div> */}
          </li>
          <li
            className={activeTab === "exehistory" ? "active" : ""}
            onClick={handleTab4}
          >
            <i id="available" class="fa-sharp fa-solid fa-circle"></i>
            Exercise History
            {/* <div className="count">{complete.length}</div> */}
          </li>
          {/* <li
            className={activeTab === "usersession" ? "active" : ""}
            onClick={handleTab5}
          >
            <i id="available" class="fa-sharp fa-solid fa-circle"></i>
            User Sessions
          </li> */}
        </ul>
      </nav>
      <article className="pyui_journeytabs-status-tab-content">
        {setActiveTab(activeTab)}
      </article>
    </section>
  );
}

export default SessionTabs;