import React, { useEffect, useRef, useState } from "react";
// import "../Admin/ManageUsers/manage.scss";
import { ToastContainer } from "react-toastify";

import { MdPreview } from "react-icons/md";
// import Beginner from "../../../assets/beginner.png";
import axios from "axios";
// import ApiConfig from "../../../ApiConfig";
import ApiConfig from "../../ApiConfig";
// import { LoaderUtility1 } from "../../Session/LoaderUtility";
import { LoaderUtility1 } from "./LoaderUtility";
import { date_helper } from "../UtilityFunctions/getCurrentWeek copy";
// import { date_helper } from "../UtilityFunctions/getCurrentWeek";
// import "../My Journey/JourneyStatusTabs/certificates1.scss";
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { CSVLink } from "react-csv";
import { SiMicrosoftexcel } from "react-icons/si";
import { useMemo } from "react";
// import {getCurrentWeek} from '../UtilityFunctions/getCurrentWeek'
// import "../My Journey/JourneyStatusTabs/Certificates.scss";
import Dialogcertificate from './Dialogcertificate'
const GroupCertificates = ({ selecttrack ,selectedgroup}) => {
  const [open, setOpen] = React.useState(false);
  const [usercertificates, setusercertificates] = useState([]);
  const [usercertificates1, setusercertificates1] = useState([]);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [totalcertificates, settotalcertificate] = useState([]);
  const [user, setUsername] = useState("");
  const [sorted3, setSorted3] = useState({ sorted: "email", reversed: true });
  const [mainTab, setMainTab] = useState("user_details");
  const [summarydata, setsummarydata] = useState([]);
  const certificateRefs = useRef([]);
  // let username = sessionStorage.getItem("username");
  let username = getValueFromSession("username");
  let usergroup = selectedgroup;
  // let username = "intern3@datasciencelab.in";
  let Api = new ApiConfig();

  useEffect(() => {
    usercertificatessummary();
    usercertificatesApi();
  }, [selecttrack,selectedgroup]);

  async function usercertificatessummary() {
    setLoading1(true);
    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI}/groupcertificatesummary?usergroup=${usergroup}&track=${selecttrack}`
        )
      )
      .then((response) => {
        console.log("$#$@#$#@$@#$@#$#@$@#$@", response.data);
        settotalcertificate(response?.data.totalcertificates);
        setsummarydata(response?.data.summary);
      });
    setLoading1(false);
  }
  async function usercertificatesApi() {
    setLoading(true);
    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI}/getGroupcertificates?usergroup=${usergroup}&track=${selecttrack}`
        )
      )
      .then((response) => {
        console.log("group response: ", response.data);
        setusercertificates(response.data);
        setusercertificates1(
          response.data.map((item) => {
            return {
              User_name: item.userid,
              Module: item.module,
              League: item.league,
              Track: item.track,
              Available: date_helper(item.available_since),
            };
          })
        );
      });
    setLoading(false);
  }

  async function downloadUserCertificates() {
    setLoading(true);
    await axios
      .get(
        Api.addApiKey(
          `${
            Api.BaseURI
          }/usercertificatesdownload?userid=${username}&track=${selecttrack}&module=${returnModule()}&league=${returnLeague()}`
        )
      )
      .then((response) => {
        console.log("response: ", response.data);
        // setusercertificates(response.data);
      });
    setLoading(false);
  }

  function setRefs() {
    certificateRefs.current = usercertificates.map(() => React.createRef());
    // setCertRef(refs)
  }

  useMemo(() => {
    setRefs();
  }, [usercertificates]);

  console.log(loading);

  let certificateRef = useRef();
  let certificateRef1 = useRef();
  let certificateRef2 = useRef();

  let refsarr = [certificateRef, certificateRef1, certificateRef2];

  const handleClickOpen = () => {
    setOpen(true);
  };


 
  const sortModule1 = () => {
    setSorted3({ sorted: "module1", reversed: !sorted3.reversed });
    const rankCopy = [...summarydata];
    rankCopy.sort((a, b) => {
      const firstA = a.module;
      const firstB = b.module;

      if (sorted3.reversed) {
        return firstA.localeCompare(firstB);
      }
      return firstB.localeCompare(firstA);

      // console.log(emailA)
      // console.log(emailB)
    });
    setusercertificates(rankCopy);
  };
  const sortmodule1 = () => {
    setSorted3({ sorted: "module1", reversed: !sorted3.reversed });
    const rankCopy = [...summarydata];
    rankCopy.sort((a, b) => {
      const lastA = a.module;
      const lastB = b.module;

      if (sorted3.reversed) {
        return lastA.localeCompare(lastB);
      }
      return lastB.localeCompare(lastA);

      // console.log(emailA)
      // console.log(emailB)
    });
    setsummarydata(rankCopy);
  };
  const sortleague = () => {
    setSorted3({ sorted: "league", reversed: !sorted3.reversed });
    const rankCopy = [...summarydata];
    rankCopy.sort((a, b) => {
      const lastA = a.league;
      const lastB = b.league;
      if (sorted3.reversed) {
        return lastA.localeCompare(lastB);
      }
      return lastB.localeCompare(lastA);

      // console.log(emailA)
      // console.log(emailB)
    });
    setsummarydata(rankCopy);
  };
  const sortcertificate = () => {
    setSorted3({ sorted: "certificate", reversed: !sorted3.reversed });
    const rankCopy = [...summarydata];
    rankCopy.sort((a, b) => {
      const lastA = a.total_certificates;
      const lastB = b.total_certificates;
      if (sorted3.reversed) {
        return lastA.localeCompare(lastB);
      }
      return lastB.localeCompare(lastA);

      // console.log(emailA)
      // console.log(emailB)
    });
    setsummarydata(rankCopy);
  };

  function fullName() {
    // let fname = sessionStorage.getItem("firstname");
    // let lname = sessionStorage.getItem("lastname");
    let fname = user.first_name;
    let lname = user.last_name;
    console.log("fname", user);
    let fullname = `${fname} ${lname}`;
    return fullname;
  }

      function toSetDate() {
        let d = Date();
        return d;
      }

  

  function returnModule() {
    // if (
    //   usercertificates !== undefined &&
    //   usercertificates[index] !== undefined
    // ) {
    //   console.log(index);
    //   return capitalizeFirstLetter(usercertificates[index].module);
    // }
    if (
      usercertificates !== undefined &&
      usercertificates[index] !== undefined
    ) {
      let module = usercertificates[index].module;
      if (module === "reader") return "Code Reading";
      else if (module === "debug") return "Code Debugging";
      else if (module === "solver") return "Problem Solving";
    }
  }

  function returnLeague() {
    if (user !== undefined && user !== "") {
      return user.league;
    }
  }

 
  function render3() {
    if (sorted3.reversed) {
      // console.log("sorted1.reversed ", sorted3.reversed)
      return <FaArrowDown />;
    } else {
      return <FaArrowUp />;
    }
  }
  const sortEmail = () => {
    setSorted3({ sorted: "email", reversed: !sorted3.reversed });
    const rankCopy = [...usercertificates];
    rankCopy.sort((a, b) => {
      const emailA = a.userid;
      const emailB = b.userid;

      if (sorted3.reversed) {
        return emailA.localeCompare(emailB);
      }
      return emailB.localeCompare(emailA);

      // console.log(emailA)
      // console.log(emailB)
    });
    setusercertificates(rankCopy);
  };

  const sortModule = () => {
    setSorted3({ sorted: "module", reversed: !sorted3.reversed });
    const rankCopy = [...usercertificates];
    rankCopy.sort((a, b) => {
      const firstA = a.module;
      const firstB = b.module;

      if (sorted3.reversed) {
        return firstA.localeCompare(firstB);
      }
      return firstB.localeCompare(firstA);

      // console.log(emailA)
      // console.log(emailB)
    });
    setusercertificates(rankCopy);
  };
  const sortLeague = () => {
    setSorted3({ sorted: "league", reversed: !sorted3.reversed });
    const rankCopy = [...usercertificates];
    rankCopy.sort((a, b) => {
      const firstA = a.league;
      const firstB = b.league;

      if (sorted3.reversed) {
        return firstA.localeCompare(firstB);
      }
      return firstB.localeCompare(firstA);
    });
    setusercertificates(rankCopy);
  };

  const sortDate = () => {
    setSorted3({ sorted: "date", reversed: !sorted3.reversed });
    const rankCopy = [...usercertificates];
    rankCopy.sort((a, b) => {
      const dateA = new Date(a.available_since);
      const dateB = new Date(b.available_since);

      if (sorted3.reversed) {
        return dateB - dateA;
      }

      return dateA - dateB;
    });
    setusercertificates(rankCopy);
  };

  function setsummaryui() {
    if (loading1) {
      return <LoaderUtility1 loading={loading1} />;
    } else {
      return (
        <>
          <div className="table-box1">
            <table>
              <thead>
                <tr>
                  <th onClick={sortmodule1}>
                    <div className="table-box-heading">
                      {/* <div className='box-heading'> */}
                      <span className="badge-name">Module</span>
                      <div>
                        {sorted3.sorted === "module1" ? render3() : null}
                      </div>

                      {/* </div> */}
                    </div>
                  </th>
                  <th onClick={sortleague}>
                    <div className="table-box-heading">
                      {/* <div className='box-heading'> */}
                      <span className="badge-name">League</span>
                      <div>
                        {sorted3.sorted === "league" ? render3() : null}
                      </div>

                      {/* </div> */}
                    </div>
                  </th>

                  <th onClick={sortcertificate}>
                    <div className="table-box-heading">
                      {/* <div className='box-heading'> */}
                      <span className="badge-name">certificate</span>
                      <div>
                        {sorted3.sorted === "certificate" ? render3() : null}
                      </div>

                      {/* </div> */}
                    </div>
                  </th>
                </tr>
              </thead>
              {summarydata?.map((items, index) => (
                <tbody>
                  <tr>
                    <td>{items.module}</td>
                    <td>{items.league}</td>
                    <td>{items.total_certificates}</td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </>
      );
    }
  }

  function setUserCertificatesUI() {
    if (loading) {
      return <LoaderUtility1 loading={loading} />;
    } else {
      if (usercertificates.length === 0) {
        return (
          <>
            <div className="table-box1">
              <table>
                <thead>
                  <tr>
                    <th>User name</th>
                    <th>Module</th>
                    <th>League</th>
                    <th>Track</th>
                    <th>Available</th>
                    <th>Preview</th>
                  </tr>
                </thead>
                <tbody>
                  <td colspan="6">
                    <div div className="pyyi_table-zerostate statealignleft">
                      No certificates are available!
                    </div>
                  </td>
                </tbody>
              </table>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div
              className="pyui_user_summary-topbar"
              style={{ justifyContent: "flex-end" }}
            >
              <CSVLink
                className={`icon-button secondary small ${
                  usercertificates1.length === 0 ? "disabled " : ""
                }`}
                title="Download as csv"
                data={usercertificates1}
                filename={"Certificates.csv"}
              >
                {/* <button className={globalRank.length === 0 ? 'primary-button disabled csv-linkbtn' : 'primary-button csv-linkbtn'} disabled={globalRank.length === 0}> */}
                <SiMicrosoftexcel size={16} />
                {/* </button> */}
              </CSVLink>
            </div>
            <div className="table-box1">
              <table>
                <thead>
                  <tr>
                    <th onClick={sortEmail}>
                      <div className="table-box-heading">
                        {/* <div className='box-heading'> */}
                        <span className="badge-name">User name</span>
                        <div>
                          {sorted3.sorted === "email" ? render3() : null}
                        </div>

                        {/* </div> */}
                      </div>
                    </th>
                    <th onClick={sortModule}>
                      <div className="table-box-heading">
                        {/* <div className='box-heading'> */}
                        <span className="badge-name">Module</span>
                        <div>
                          {sorted3.sorted === "module" ? render3() : null}
                        </div>

                        {/* </div> */}
                      </div>
                    </th>
                    <th onClick={sortLeague}>
                      <div className="table-box-heading">
                        {/* <div className='box-heading'> */}
                        <span className="badge-name">League</span>
                        <div>
                          {sorted3.sorted === "league" ? render3() : null}
                        </div>

                        {/* </div> */}
                      </div>
                    </th>
                    <th>Track</th>
                    <th onClick={sortDate}>
                      <div className="table-box-heading">
                        {/* <div className='box-heading'> */}
                        <span className="badge-name">Available</span>
                        <div>
                          {sorted3.sorted === "date" ? render3() : null}
                        </div>

                        {/* </div> */}
                      </div>
                    </th>
                    <th>Preview</th>
                  </tr>
                </thead>
                {usercertificates.length === 0 ? (
                  <tbody>
                    <td colspan="5">
                      <div className="pyyi_table-zerostate">
                        No certificates available!
                      </div>
                    </td>
                  </tbody>
                ) : (
                  <>
                    {usercertificates.map((items, index) => (
                      <tbody>
                        <tr>
                          <td>{items.userid}</td>
                          <td>{items.module}</td>
                          <td>{items.league}</td>
                          <td>{items.track}</td>
                          <td>{date_helper(items.available_since)}</td>
                          <td
                            onClick={(e) => {
                              e.preventDefault();
                              setUsername(items);
                              handleClickOpen();
                              setIndex(index);
                              // exportComponentAsPNG(certificateWrapper, {
                              //   html2CanvasOptions: { backgroundColor: null },
                              // });
                            }}
                          >
                            {<MdPreview size={24} />}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </>
                )}
              </table>
            </div>
          </>
        );
      }
    }
  }

  return (
    <>
      <section className="pyui_myJourneyTab-container">
        <ToastContainer />
        <div className="title"> Certificates </div>
        <div className="certificate-app">
          <div className="pyui_user_summary-topbar">
            <div>
              {" "}
              <p className="details-title">
                {console.log("simere", totalcertificates)}
                {totalcertificates.length > 0
                  ? totalcertificates[0].users
                  : 0}{" "}
                distinct users received{" "}
                {totalcertificates.length > 0
                  ? totalcertificates[0].certificates
                  : 0}{" "}
                certificates.
              </p>
            </div>
          </div>
          <div className="pyui_user_summary">
            <nav className="pyui_tabbar_streched">
              <span
                className={`tab-item ${
                  mainTab === "user_details" ? "selected" : ""
                }`}
                onClick={() => {
                  setMainTab("user_details");
                }}
              >
                Summary
              </span>

              <span
                className={`tab-item ${
                  mainTab === "Details" ? "selected" : ""
                }`}
                onClick={() => {
                  setMainTab("Details");
                }}
              >
                Details
              </span>
            </nav>

            {mainTab === "user_details" && (
              <>
                <div className="pyui_user_summary-topbar"></div>
                <div className="pyui_user_summary-table">{setsummaryui()}</div>
              </>
            )}

            {mainTab === "Details" && (
              <>
                <div className="pyui_user_summary-topbar"></div>
                <div className="pyui_user_summary-table">
                  {setUserCertificatesUI()}
                </div>
              </>
            )}
          </div>
          <div></div>
        </div>
      </section>

      {open && (
        <Dialogcertificate
          open={open}
          setOpen={setOpen}
          fname={user?.first_name}
          lname={user?.last_name}
          setLoading={setLoading}
          loading={loading}
          selecttrack={selecttrack}
          downloadUserCertificates={downloadUserCertificates}
          usercertificates={usercertificates}
          index={index}
          certificateRefs={certificateRefs}
          isadmin={true}
          returnLeague={returnLeague}
          toSetDate ={toSetDate}
        />
      )}
    </>
  );
};

export default GroupCertificates;
