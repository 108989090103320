export function getWeekN() {
  let date = new Date();
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  var weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
  return weekNo;
}

export function idDate() {
  let date = new Date();
  let d = new Date();
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  var weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
  var newId = d.getFullYear() + '_' + getFirstDayOfWeek() + '_' + weekNo
  // return d.getFullYear() + '_' + d.getDate() + '_' + weekNo;
  return newId;
}

export function getFirstDayOfWeek() {
  var curr = new Date; // get current date
  var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
  var last = first + 6; // last day is the first day + 6

  var firstday = new Date(curr.setDate(first))
  var lastday = new Date(curr.setDate(last))

  // console.log(date_helper1(firstday))
  firstday = date_helper2(firstday)
  return firstday

}


function date_helper2(date) {
  const d = new Date(date);
  return ("0" + (d.getDate())).slice(-2);
}


export function date_helper1(date) {
  const d = new Date(date);
  return (
    d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + (d.getDate())).slice(-2)
  );
}
export function date_helper(date) {
  const d = new Date(date);
  return (
    ("0" + (d.getDate())).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear()
  );
}

export function nextsundayDate() {
  let index = 0;
  var sunday = new Date();
  sunday.setDate(sunday.getDate() + (index - 1 - sunday.getDay() + 7) % 7 + 1);
  return date_helper(sunday);
}