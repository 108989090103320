import React, { useEffect, useState } from "react";
import axios from "axios";
import ApiConfig from "../../ApiConfig";
import * as moment from "moment";
import ReactApexChart from "react-apexcharts";
// import "./userhistorygrp.scss";

const UserHistoryGrp = ({ excount, badgePoints, respLength }) => {
  // const [totalBadgePoints, setTotalBadgePoints] = useState([]);
  // const [excount, setExcount] = useState([]);
  // const [attendence, setAttendence] = useState([]);
  // const [badgePoints, setbadgePoints] = useState([]);
  // const [exercises, setExercises] = useState([]);
  // const [exercises,setExercises] = useState([]);
  // let user_group = sessionStorage.getItem('usergroup');
  // let userid = sessionStorage.getItem('username');

  // let user_group = 'demo group';
  // let userid = 'intern3@datasciencelab.in';
  // let language = sessionStorage.getItem("language");
  // let language = 'C';
  // let Api = new ApiConfig();

  // function weekbyweek(week1) {

  //   let currentDate = new Date();
  //   // currentDate.setMonth(3)
  //   // currentDate.setDate(27);
  //   // console.log("current date was",currentDate)

  //   let push1 = [0, 7, 14, 21, 28];
  //   let check = []
  //   let week3 = [];
  //   let check3 = [];
  //   let finalarr = [];
  //   let finalarr1 = [];
  //   let attendencearr = [];
  //   // console.log("current data is", currentDate)
  //   var year1 = new Date(currentDate.getFullYear(), 0, 1);
  //   var days = Math.floor((currentDate - year1) / (24 * 60 * 60 * 1000));
  //   for (let i = 0; i < 5; i++) {
  //     var week = Math.ceil((currentDate.getDay() - push1[i] + days) / 7);
  //     check.push({ week: week.toString(), attendence: '0', exercises: '0', badgepoints: '0' })
  //   }
  //   check = check.reverse()
  //   // console.log("final week is", check);
  //   // console.log("incoming week", week1)
  //   for (let k = 0; k < week1.length; k++) {
  //     week3.push(week1[k].week)
  //   }

  //   // console.log("week3", week3);
  //   for (let r = 0; r < check.length; r++) {
  //     check3.push(check[r].week)
  //   }
  //   // console.log("check3", check3)

  //   for (let j = 0; j < week1.length; j++) {
  //     // week3.push(week1[j].week)
  //     if (check3.includes(week3[j])) {
  //       check[check3.indexOf(week3[j])] = week1[j]
  //     }
  //   }
  //   // console.log(check)

  //   for (let i = 0; i < check.length; i++) {
  //     // console.log("dfdf", check[i].exercises)
  //     finalarr.push(check[i].exercises)
  //     finalarr1.push(check[i].badgepoints)
  //     attendencearr.push(check[i].attendence)
  //   }

  //   // console.log("finalarr", finalarr)
  //   // console.log("attendencearr", attendencearr)
  //   setExcount(finalarr)
  //   setTotalBadgePoints(finalarr1);
  //   setAttendence(attendencearr);
  // }

  // const fetchWeekByProgress = async () => {
  //   // console.log(Api.addApiKey(`${Api.BaseURI}/weekbygrouprogress?usergroup=${user_group}&track=${language}`));
  //   // let user_group = 'JSM College'
  //   await axios.get(
  //     Api.addApiKey(`${Api.BaseURI}/weekbygrouprogress?usergroup=${user_group}&track=${language}`))
  //     .then(resp => {
  //       // console.log("exercisehistorygroup", resp.data);
  //       weekbyweek(resp.data)
  //     });
  // }
  // useEffect(() => {
  //   fetchData();
  // }, [user_group, language]);

  // console.log("datearr", datearr)

  const series = [
    {
      name: "Badge Points",
      type: "column",
      data: respLength === 0 ? [] : badgePoints,
      // data: attendence,
    },
    {
      name: "Exercise Count",
      type: "line",
      data: respLength === 0 ? [] : excount,
      // data: []
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    noData: {
      text: "No activity during the last 5 weeks",
      align: "center",
      verticalAlign: "middle",
    },
    stroke: {
      width: [0, 4],
    },
    markers: {
      size: 5,
    },
    // title: {
    //   text: 'Exercise History'
    // },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      style: {
        colors: ["#50C878"],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        borderWidth: 0,
      },
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels:
      respLength === 0
        ? [0]
        : [
            "4 Weeks Ago",
            "3 Weeks Ago",
            "2 Weeks Ago",
            "Last Week",
            "This Week",
          ],
    xaxis: {
      title: {
        text: respLength === 0 ? "" : "Last 5 Weeks",
      },
    },
    yaxis: [
      {
        title: {
          text: respLength === 0 ? "" : "Badge Points",
        },
      },
      {
        opposite: true,
        title: {
          text: respLength === 0 ? "" : "Execise Count",
        },
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: [
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " points";
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + `${y === 1 ? " exercise" : " exercises"}`;
            }
            return y;
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="progress-block-wrapper">
        <span className="progress-block-title">Exercises solved</span>
        <ReactApexChart
          id="chart"
          options={options}
          series={series}
          type="bar"
          height={300}
        />
      </div>
    </>
  );
};

export default UserHistoryGrp;
