import axios from "axios";
import React, { useEffect, useState, } from "react";

import ApiConfig from "../../ApiConfig";
// import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
// import Tabs from "../Tabs/Tabs1";
import Tabs from '../Tabs/Tabs1'
import { Context } from '../ErrorWrappers/conetxt';
import { useContext } from 'react';
import SelectLang from '../Modules/SelectLang'
import SelectGroup from "../Modules/SelectGroup";
// import selectGroup from './'
import SessionTabs from './SessionTabs'
// import { getValueFromSession } from "../../../../react-ui/src/components/UtilityFunctions/getSetValueSession";
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";

export default function SessionPage({ selectedtrack }) {

  const [isLoading, setIsLoading] = useState(false)

  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);

  const [selected, setSelected] = useState([])
  const [selecttrack, setselecttrack] = useState('')
  const [groupdata,setGroupdata]=useState([])
  const [selectedgroup,setselectedGroup]=useState('')
  useEffect(() => {
    handleGrouptracks();
  }, [])
  // console.log(selectedtrack)
  console.log("selectedgroup",selectedgroup)

  let Api = new ApiConfig();
  let track = useContext(Context);
  // let track = selectedtrack;
  // let selectedtrack = sessionStorage.getItem('language');
  // let userid = sessionStorage.getItem("username");
  let userid = getValueFromSession('username');
  // let group = sessionStorage.getItem("usergroup");
  let group=selectedgroup

  // let group = 'JSM College'


  async function handleGrouptracks() {
    console.log(
      Api.addApiKey(`${Api.BaseURI}/tracksforgroup?usergroup=${group}`)
    );
    await axios
      .get(Api.addApiKey(`${Api.BaseURI}/tracksforgroup?usergroup=${group}`))
      .then((res) => {
        if (res.data.result.length > 0) {
          // let array=(res.data.result.map((item)=>item.track));
          setSelected(res.data.result);
          setselecttrack(res.data.result[0].track);
          // console.log("335", res.data.result[0].track);
        } else {
          setSelected([]);
        }
      });
  }

  async function handleGrouptracks() {
    console.log(
      Api.addApiKey(`${Api.BaseURI}/tracksforgroup?usergroup=${group}`)
    );
    await axios
      .get(Api.addApiKey(`${Api.BaseURI}/tracksforgroup?usergroup=${group}`))
      .then((res) => {
        if (res.data.result.length > 0) {
          // let array=(res.data.result.map((item)=>item.track));
          setSelected(res.data.result);
          setselecttrack(res.data.result[0].track);
          // console.log("335", res.data.result[0].track);
        } else {
          setSelected([]);
        }
      });
  }
  function GetUSerGroups(){
		axios.get(Api.addApiKey(`${Api.BaseURI}/getusergroups`)).then(res=>{
		  if(res.data.length>0){
       setGroupdata(res.data)
       setselectedGroup(res.data[0].user_group)
      }
	
		})
	}


  // useEffect(() => {
  //   // getSessionInfo();
  //   // getUserEvalResp();
  //   // getUserSessionResp();
  //   evalResponseSummary("30days", "", "", sessionStorage.getItem("username"));
  //   getUserResposneRecords(
  //     "30days",
  //     "",
  //     "",
  //     sessionStorage.getItem("username")
  //   );
  //   userSessionSummary("30days", "", "", sessionStorage.getItem("username"));
  //   getDistinctUserResponse(
  //     "30days",
  //     "",
  //     "",
  //     sessionStorage.getItem("username")
  //   );
  //   getDistinctEvalResponse(
  //     "30days",
  //     "",
  //     "",
  //     sessionStorage.getItem("username")
  //   );
  //   getDistinctSessionUsers(
  //     "30days",
  //     "",
  //     "",
  //     sessionStorage.getItem("username")
  //   );
  //   getUserSummaryInfo();
  //   getClasssProgresSummary();
  //   fetchWeekByProgress();
  // }, [selecttrack]);

  useEffect(() => {
    GetUSerGroups()
  }, []);
  useEffect(()=>{
    handleGrouptracks();

  },[selectedgroup])


  return (

    <>
      <section className="pyui_main-content-section " style={{paddingTop:'1rem'}}>

        {/* <Breadcrumbs /> */}
        <Tabs />
        {/* <div className="session_title">Team Learnings</div> */}
        <section className="pyui_main-container-section-content pyui_journey-section"
        >
          <div
            className="session_track"
            // key={track.selected1}
            style={{display:'flex',alignItems:'center'}}
          >
            {/* <div className="session_track-name">
						<div className="track_name">Track : {track.selected1}</div>
					</div> */}

          
         {groupdata.length !== 0 ?
              <div className="pyui_module-select-track-wrapper" style={{ marginBottom: '1rem' }}>
                <div className="pyui_module-select-track-label">
                  Select a Group :
                </div>
                <div>
                  <SelectGroup isActive={isActive} setIsActive={setIsActive} selected={groupdata} setSelected={setGroupdata} setselecttrack={setselectedGroup} selecttrack={selectedgroup} />
                </div>
              </div>
              :
              <div className="pyui_module-select-track-wrapper" style={{ marginBottom: '1rem' }}>
                <div className="pyui_module-select-track-label">
                  No Group Available
                </div>
              </div>}
            {selected.length !== 0 ?
              <div className="pyui_module-select-track-wrapper" style={{ marginBottom: '1rem' }}>
                <div className="pyui_module-select-track-label">
                  Select a track :
                </div>
                <div>
                  <SelectLang isActive={isActive1} setIsActive={setIsActive1} selected={selected} setSelected={setSelected} setselecttrack={setselecttrack} selecttrack={selectedgroup} />
                </div>
              </div>
              :
              <div className="pyui_module-select-track-wrapper" style={{ marginBottom: '1rem' }}>
                <div className="pyui_module-select-track-label">
                  No Track History Available
                </div>
              </div>}

          </div>


          <SessionTabs
            selecttrack={selecttrack}
            selectedgroup={selectedgroup}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </section>
      </section >
    </>
  )
}
