import React from "react";
import { Link, useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import "../Explore/explore.scss";

export default function Tabs1() {
  let { pathname } = useLocation();
  let data;

  function booleanAdminTeacherTabs() {
    return (
      (sessionStorage.getItem("role") !== undefined &&
        sessionStorage.getItem("role") === "teacher") ||
      sessionStorage.getItem("role") === "admin"
    );
  }

  function booleanAdmin() {
    return (
      (sessionStorage.getItem("role") !== undefined &&
        sessionStorage.getItem("role") === "admin")
    )
  }

  function booleaTeacher() {
    return (
      (sessionStorage.getItem("role") !== undefined &&
        sessionStorage.getItem("role") === "teacher")
    )
  }

  function adminTabs() {
    let tabs = [];
    tabs.push({
      id: 1,
      label: "Progress Board",
      link: "/Progress",
    });
    tabs.push({
      id: 2,
      label: "Manage Users",
      link: "/Users",
    });

    let payments = process.env.REACT_APP_SHOW_PAYMENTS;
    if (payments === 'true')
    {
      tabs.push({
        id: 3,
        label: "Manage Subscriptions",
        link: "/Managesubscription",
      });
    }



    return tabs
  }

  function teacherTabs() {
    let tabs = [];
    tabs.push({
      id: 1,
      label: "My Tracks",
      link: "/Tracks",
    });
    tabs.push({
      id: 2,
      label: "Progress Board",
      link: "/Progress",
    });

    return tabs;
  }

  function showTabs() {
    let tabs = [];

    tabs.push({
      id: 1,
      label: "My Tracks",
      link: "/tracks",
    });
    tabs.push({
      id: 2,
      label: "My Journey",
      link: "/Journey",
    });
    let payments = process.env.REACT_APP_SHOW_PAYMENTS;
    if (payments === 'true')
    {
      tabs.push({
        id: 3,
        label: "My Subscriptions",
        link: "/subscription",
      });
    }
    return tabs;
  }

  function setAdminTeacherTabs() {
    let tabsdata = [];
    if (booleanAdmin()) {
      tabsdata = adminTabs()
    }
    if (booleaTeacher()) {
      tabsdata = teacherTabs()
    }
    console.log("tabsdata", tabsdata)
    return tabsdata;
  }

  data = booleanAdminTeacherTabs() ? setAdminTeacherTabs() : showTabs();
  console.log("data: ", data);
  return (
    <nav className="pyui_main-tabs" role="tablist">
      <Tabs
        textColor="inherit"
        style={{ fontFamily: "inherit" }}
        value={pathname}
        className="pyui_tabbar"
      >
        {data.map((item, index) => {
          return (
            <Tab
              key={index}
              value={item.link}
              component={Link}
              label={item.label}
              to={item.link}
              className="pyui-tabitem"
              style={{ fontFamily: "inherit", opacity: 0.9 }}
            />
          );
        })}
      </Tabs>
    </nav>
  );
}
