import React, { useEffect, useRef, useState } from 'react'
import ApiConfig from '../../ApiConfig';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { LoaderUtility1 } from './LoaderUtility';
import { CSVLink } from 'react-csv';
import { SiMicrosoftexcel } from 'react-icons/si';
import { FiRefreshCcw } from 'react-icons/fi';
import axios from 'axios';

const Exercisehistory = ({ selecttrack,selectedgroup }) => {
  const [userresponse, setUserResponse] = useState([]);
  const [activeUsersLoader, setActiveLoader] = useState(false);
  const [distinctuserresponse, setDistinctUserResponse] = useState(0);
  const [option, setOption] = useState("30days");
  const [selected1, setSelected1] = useState("Last 30 days");
  const [isActive1, setIsActive1] = useState(false);
  const [title, setTitle] = useState(true);
  const [sorted, setSorted] = useState({ sorted: "email", reversed: true })
  let Api = new ApiConfig();

  async function getUserResposneRecords(title, date1, date2, userid) {
    setActiveLoader(true);
    // console.log("api", (Api.addApiKey(`${Api.BaseURI}/getuserresponsesummary?usergroup=${sessionStorage.getItem('usergroup')}&title=${title}&date1=${date1}&date2=${date2}&&userid=${userid}`)));
    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI
          }/getuserresponsesummary?usergroup=${selectedgroup}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}&track=${selecttrack}`
        )
      )
      .then((resp) => {
        if (resp.data.status === 200) {
          console.log("exercises his", resp.data);

          setUserResponse(resp.data.result.map(item => {
            return {
              First_name: item.First_Name,
              Last_name: item.Last_Name,
              User_name: item.Email,
              Exercises_completed: item.Exercises_completed,
              Score: item.Score,
              Total_marks: item.Total_marks,
              Badge_points_earned: item.Badge_points_earned,
            }
          }));
        }
        setActiveLoader(false);
      });

    // return userresponse
  }

  async function getDistinctUserResponse(title, date1, date2, userid) {
    setActiveLoader(true);
    // console.log(Api.addApiKey(`${Api.BaseURI}/distinctuserresponse?usergroup=${sessionStorage.getItem('usergroup')}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}`));
    await axios
      .get(
        Api.addApiKey(
          `${Api.BaseURI
          }/distinctuserresponse?usergroup=${selectedgroup}&title=${title}&date1=${date1}&date2=${date2}&userid=${userid}&track=${selecttrack}`
        )
      )
      .then((resp) => {
        if (resp.data.status === 200) {
          // console.log("getDistinctUserResponse", resp.data.result);
          setDistinctUserResponse(resp.data.result[0]);
        }
        setActiveLoader(false);
      });
  }
  useEffect(() => {

    getUserResposneRecords("30days", "", "", sessionStorage.getItem("username"));

    getDistinctUserResponse(
      "30days",
      "",
      "",
      sessionStorage.getItem("username")
    );

  }, [selecttrack,selectedgroup]);

  let menuRef1 = useRef();
  // console.log("menuRef", menuRef)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef1.current && !menuRef1.current.contains(event.target)) {
        setIsActive1(false);
        // setIsDateActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef1]);

  function setDropDown() {
    return (
      <>
        <div ref={menuRef1} className="pyui_session">
          <div
            className={`pyui_session-inner ${isActive1 ? "expanded" : ""}`}
            onClick={(e) => setIsActive1(!isActive1)}
          >
            <span>{selected1}</span>
          </div>
          {isActive1 && (
            <ul className="pyui_session-list">
              {/* <li className='pyui_session-list-item'
								onClick={(e) => {
									setSelected1(e.target.textContent)

									evalResponseSummary('session', '', '', sessionStorage.getItem('username'));
									getUserResposneRecords('session', '', '', sessionStorage.getItem('username'));
									getUserResposneRecords('session', '', '', sessionStorage.getItem('username'));
									getDistinctUserResponse('session', '', '', sessionStorage.getItem('username'));
									getDistinctEvalResponse('session', '', '', sessionStorage.getItem('username'));
									getDistinctUserResponse('session', '', '', sessionStorage.getItem('username'));
									setIsActive1(false);
									setOption('Latest session');
									// getSessionInfo()
									// getUserEvalResp()
									// getUserSessionResp()
									setTitle(true)
								}}
							>
								Latest session
							</li> */}
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  setSelected1(e.target.textContent);
                  getUserResposneRecords("today");
                  getDistinctUserResponse("today");
                  setOption("today");
                  setIsActive1(false);
                  setTitle(false)
                }}
              >
                Today
              </li>
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('7days')
                  setSelected1(e.target.textContent);
                  getDistinctUserResponse("7days");
                  getUserResposneRecords("7days");
                  setOption("7days");
                  setIsActive1(false);
                  setTitle(false)
                }}
              >
                Last 7 days
              </li>
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('15days');
                  setSelected1(e.target.textContent);
                  getDistinctUserResponse("15days");
                  getUserResposneRecords("15days");
                  setOption("15days");
                  setIsActive1(false);
                  setTitle(false)
                }}
              >
                Last 15 days
              </li>
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('30days');
                  setSelected1(e.target.textContent);
                  getUserResposneRecords("30days");
                  getDistinctUserResponse("30days");
                  setOption("30days");
                  setIsActive1(false);
                  setTitle(false);
                }}
              >
                Last 30 days
              </li>
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('30days');
                  setSelected1(e.target.textContent);
                  getUserResposneRecords("90days");
                  getDistinctUserResponse("90days");
                  setOption("90days");
                  setIsActive1(false);
                  setTitle(false);
                }}
              >
                Last 90 days
              </li>
              {/* <li className='pyui_session-list-item'
								onClick={(e) => {
									// setTitle('30days');
									setSelected1(e.target.textContent);

									evalResponseSummary('180days')

									getUserResposneRecords('180days');
									getUserResposneRecords('180days');
									getDistinctUserResponse('180days');
									getDistinctEvalResponse('180days');
									getDistinctUserResponse('180days');
									setOption('180days');
									setIsActive1(false);
									setTitle(false);
								}}
							>
								Last 180 days
							</li> */}
              <li
                className="pyui_session-list-item"
                onClick={(e) => {
                  // setTitle('30days');
                  setSelected1(e.target.textContent);
                  getUserResposneRecords("all");
                  getDistinctUserResponse("all");
                  setOption("all");
                  setIsActive1(false);
                  setTitle(false);
                }}
              >
                Entire period
              </li>
              {/* <li className={`pyui_session-list-item-date ${isDateActive ? "expand" : ""}`}
								onClick={(e) => {
									setIsDateActive(!isDateActive);
									// setTitle('custom');
									// getUserResposneRecords()
									setSelected1(e.target.textContent);
									setTitle(false);
								}}
							>
								<span className='date-picker-title'>Custom date range</span>
							</li>
							{isDateActive && (
								<li className='pyui_session-list-date'>
									<div className='custom-date'>
										<input className='date-input-field' min="2020-01-01" max={dateFormat()} value={date1} onChange={e => setDate1(e.target.value)} type="date" />
										to
										<input className='date-input-field' min="2020-01-01" max={dateFormat()} value={date2} onChange={e => {
											setDate2(e.target.value);
										}} type="date" />
										<button className='primary-button' onClick={() => {
											evalResponseSummary('custom', date1, date2);
											getUserResposneRecords('custom', date1, date2);
											getUserResposneRecords('custom', date1, date2);
											getDistinctUserResponse('custom', date1, date2);
											getDistinctEvalResponse('custom', date1, date2);
											getDistinctUserResponse('custom', date1, date2);
											setOption('custom');
											setIsActive1(false);
											setIsDateActive(!isDateActive);
											// setTitle(false);
										}}>Get details</button>
									</div>
								</li>
							)} */}
            </ul>
          )}
        </div>
      </>
    );
  }
  function setResponseTable() {
    // console.log(userresponse)

    // let resparr = userresponse.length === 0 ? sessionResponseUsers : userresponse;
    // settablename("setResponseTable")
    const sortFirstName = () => {
      setSorted({ sorted: "first", reversed: !sorted.reversed });
      const activeusers = [...userresponse];
      activeusers.sort((a, b) => {
        // console.log("a,b", a, b);
        const firstA = a.First_name;
        const firstB = b.First_name;

        if (sorted.reversed) {
          return firstA.localeCompare(firstB);
        }
        return firstB.localeCompare(firstA);

        // console.log(emailA)
        // console.log(emailB)
      });
      setUserResponse(activeusers);
      // activeUsernames = activeusers
    };
    const sortLastName = () => {
      setSorted({ sorted: "last", reversed: !sorted.reversed });
      const activeusers = [...userresponse];
      activeusers.sort((a, b) => {
        const lastA = a.Last_name;
        const lastB = b.Last_name;

        if (sorted.reversed) {
          return lastA.localeCompare(lastB);
        }
        return lastB.localeCompare(lastA);

        // console.log(emailA)
        // console.log(emailB)
      });
      setUserResponse(activeusers);
    };
    const sortEmail = () => {
      setSorted({ sorted: "email", reversed: !sorted.reversed });
      const activeusers = [...userresponse];
      activeusers.sort((a, b) => {
        const emailA = a.User_name;
        const emailB = b.User_name;

        if (sorted.reversed) {
          return emailA.localeCompare(emailB);
        }
        return emailB.localeCompare(emailA);

        // console.log(emailA)
        // console.log(emailB)
      });
      // setGlobalRank(rankCopy);
      setUserResponse(activeusers);
    };
    const sortExercises = () => {
      setSorted({ sorted: "ex_completed", reversed: !sorted.reversed });
      const rankCopy = [...userresponse];
      rankCopy.sort((a, b) => {
        if (sorted.reversed) {
          return a.Exercises_completed - b.Exercises_completed;
        }
        return b.Exercises_completed - a.Exercises_completed;

        // console.log(emailA)
        // console.log(emailB)
      });
      setUserResponse(rankCopy);
    };
    const sortScore = () => {
      setSorted({ sorted: "score", reversed: !sorted.reversed });
      const rankCopy = [...userresponse];
      rankCopy.sort((a, b) => {
        if (sorted.reversed) {
          return a.Score - b.Score;
        }
        return b.Score - a.Score;

        // console.log(emailA)
        // console.log(emailB)
      });
      setUserResponse(rankCopy);
    };
    const sortTotalMarks = () => {
      setSorted({ sorted: "totalmarks", reversed: !sorted.reversed });
      const rankCopy = [...userresponse];
      rankCopy.sort((a, b) => {
        if (sorted.reversed) {
          return a.Total_marks - b.Total_marks;
        }
        return b.Total_marks - a.Total_marks;

        // console.log(emailA)
        // console.log(emailB)
      });
      setUserResponse(rankCopy);
    };
    const sortBadges = () => {
      setSorted({ sorted: "badges", reversed: !sorted.reversed });
      const rankCopy = [...userresponse];
      rankCopy.sort((a, b) => {
        if (sorted.reversed) {
          return a.Badge_points_earned - b.Badge_points_earned;
        }
        return b.Badge_points_earned - a.Badge_points_earned;

        // console.log(emailA)
        // console.log(emailB)
      });
      setUserResponse(rankCopy);
    };
    return (
      <>
        <div className="pyui_user_summary-table-inner table-scroll">
          <table>
            <thead>
              <tr>
                <th>
                  <div className="table-box-heading">
                    <span>#</span>
                  </div>
                  {/* <span>{sorted.sorted === "id" ? renderArrow() : null}</span> */}
                </th>
                <th onClick={sortFirstName}>
                  <div className="table-box-heading">
                    <span className="badge-name">First name</span>
                    <div>{sorted.sorted === "first" ? renderArrow() : null}</div>
                  </div>
                </th>
                <th onClick={sortLastName}>
                  <div className="table-box-heading">
                    <span className="badge-name">Last name</span>
                    <div>{sorted.sorted === "last" ? renderArrow() : null}</div>
                  </div>
                </th>
                <th onClick={sortEmail}>
                  <div className="table-box-heading">
                    <span className="badge-name">User name</span>
                    <div>{sorted.sorted === "email" ? renderArrow() : null}</div>
                  </div>
                </th>
                <th onClick={sortExercises}>
                  <div className="table-box-heading">
                    <span className="badge-name">Exercises completed</span>
                    <div>
                      {sorted.sorted === "ex_completed" ? renderArrow() : null}
                    </div>
                  </div>
                </th>
                <th onClick={sortScore}>
                  <div className="table-box-heading">
                    <span className="badge-name">Score</span>
                    <div>{sorted.sorted === "score" ? renderArrow() : null}</div>
                  </div>
                </th>
                <th onClick={sortTotalMarks}>
                  <div className="table-box-heading">
                    <span className="badge-name">Total marks</span>
                    <div>
                      {sorted.sorted === "totalmarks" ? renderArrow() : null}
                    </div>
                  </div>
                </th>
                <th onClick={sortBadges}>
                  <div className="table-box-heading">
                    <span className="badge-name">Badge points</span>
                    <div>{sorted.sorted === "badges" ? renderArrow() : null}</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {userresponse.map((items, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{items.First_name}</td>
                  <td>{items.Last_name}</td>
                  <td>{items.User_name}</td>
                  <td>{items.Exercises_completed}</td>
                  <td>{items.Score}</td>
                  <td>{items.Total_marks}</td>
                  <td>{items.Badge_points_earned}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  function setExerciseSumTitle(distinctuserresponse) {
    console.log("distinctuserresponse", distinctuserresponse);
    let distinct_excount = parseInt(distinctuserresponse.distinct_excount) || 0;
    let user_count = parseInt(distinctuserresponse.user_count) || 0;
    let excount = parseInt(distinctuserresponse.excount) || 0;
    // console.log("distinct_excount", distinct_excount);
    // console.log("user_count", user_count);
    // console.log("excount", excount);
    // console.log("date1", date1);
    // console.log("date2", date2);
    let title = "";
    let user = user_count > 1 ? "users" : "user";
    let exercises = distinct_excount > 1 ? "exercises" : "exercise";

    if (option === "Latest session") {
      title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during latest session`;
    } else if (option === "today") {
      title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during today's session.`;
    } else if (option === "7days") {
      title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during last 7 days.`;
    } else if (option === "15days") {
      title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during last 15 days.`;
    } else if (option === "30days") {
      title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during last 30 days.`;
    } else if (option === "90days") {
      title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during last 90 days.`;
    } else if (option === "180days") {
      title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during last 180 days.`;
    } else if (option === "all") {
      title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises during entire period.`;
    }
    // else if (option === "custom") {
    //   title = `Total ${user_count} distinct ${user} have submitted ${distinct_excount} distinct ${exercises} out of ${excount} exercises between ${date_helper(
    //     date1
    //   )} and ${date_helper(date2)}.`;
    // }

    // console.log("title", title);
    return title;
  }

  const renderArrow = () => {
    if (sorted.reversed) {
      // console.log("sorted.reversed ", sorted.reversed)
      return <FaArrowDown />;
    } else {
      return <FaArrowUp />;
    }
  };
  return (
    <section className='pyui_user_summary'>
      <div className="title">Exercise History</div>
      <div className="select-interval">
        <span className="interval-title">Select interval</span>
        {setDropDown()}
        <button
          title="Refresh"
          onClick={() => {
            getUserResposneRecords("30days", "", "", sessionStorage.getItem("username"));

            getDistinctUserResponse(
              "30days",
              "",
              "",
              sessionStorage.getItem("username")
            );
          }}
          className={
            title === true
              ? "icon-button small"
              : "icon-button small disabled"
          }
        >
          <FiRefreshCcw size={16} />
        </button>
      </div>
      <div className="pyui_user_summary-topbar">
        <span>
          {parseInt(distinctuserresponse) === 0 ? (
            <></>
          ) : (
            <p className="details-title">
              {setExerciseSumTitle(distinctuserresponse)}
            </p>
          )}
        </span>
        <button className={
          userresponse.length === 0
            ? "icon-button secondary disabled"
            : "icon-button secondary"
        }>
          <CSVLink
            title="Download as csv"
            data={userresponse}
            filename={"Exercise History.csv"}
          >
            {/* <button className={sessionSummary.length === 0 && activeUsernames.length === 0 ? 'primary-button disabled' : 'primary-button'}
											disabled={sessionSummary.length === 0 && activeUsernames.length === 0}> */}
            <SiMicrosoftexcel color="white" />
            {/* </button> */}
          </CSVLink>
        </button>
      </div>
      {activeUsersLoader
        ? (
          <>
            <LoaderUtility1 loading={activeUsersLoader} />
          </>
        ) : (
          <div className="pyui_user_summary-table">
            {setResponseTable()}
          </div>
        )}
    </section>
  )
}

export default Exercisehistory