import React, { useEffect, useState } from "react";
import axios from "axios";
import ApiConfig from "../../ApiConfig";
import * as moment from "moment";
import ReactApexChart from "react-apexcharts";
// import { motion } from 'framer-motion';
// import "./userhistorygrp.scss";

const UserGrpAttendence = ({ attendence, respLength }) => {
  // console.log("attendence", attendence)
  const series = [
    {
      name: "Attendence",
      type: "line",
      // data: badgePoints,
      data: respLength === 0 ? [] : attendence,
    },
    // {
    //   name: 'Exercise Count',
    //   type: 'line',
    //   data: excount
    //   // data: []
    // }
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: true,
        offsetY: -25,
        // offsetX: -5
      },
    },
    noData: {
      text: "No activity during the last 5 weeks",
      align: "center",
      verticalAlign: "middle",
    },
    colors: ["#50C878"],
    stroke: {
      width: [4],
      curve: "smooth",
    },
    // markers: {
    //   size: 5,
    // },
    // title: {
    //   text: 'Exercise History'
    // },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      style: {
        colors: ["#50C878"],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        borderWidth: 0,
      },
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels:
      respLength === 0
        ? [""]
        : [
            "4 Weeks Ago",
            "3 Weeks Ago",
            "2 Weeks Ago",
            "Last Week",
            "This Week",
          ],
    xaxis: {
      title: {
        text: respLength === 0 ? "" : "Last 5 Weeks",
      },
    },

    yaxis: [
      {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
        title: {
          text: respLength === 0 ? "" : "User count",
        },
      },
      //  {
      //   opposite: true,
      //   title: {
      //     text: 'Execise Count'
      //   }
      // }
    ],
  };

  return (
    <>
      <div className="progress-block-wrapper">
        <span className="progress-block-title">Sessions attended</span>
        <ReactApexChart
          id="chart"
          options={options}
          series={series}
          type="line"
          height={300}
        />
      </div>
    </>
  );
};

export default UserGrpAttendence;
