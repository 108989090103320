import React from "react";
import { useState } from "react";
import "./SelectLang.scss";

const SelectLang = (props) => {


  const [index, setIndex] = useState(0)
  let language = sessionStorage.getItem('language');
  let { isActive, setIsActive, selected, setSelected,
    selecttrack, setselecttrack } = props;


  console.log("selected", selected)


  // var unlockedUserIds = ["javed.inamdar@datasciencelab.in", "anjum@datasciencelab.in", "dpapte@gmail.com", "yasmeen.sayyed@gmail.com", "debug1@gmail.com", "debug2@gmail.com"];

  function toEnableDisable() {
    let language = process.env.REACT_APP_SHOW_PYTHON;
    return (language !== undefined && language === 'true');
  }

  function returnTrack(track) {
    if (track === 'C') return 'C Programming'
    else if (track === 'Python') return 'Python Programming'
    else if (track === 'Visual Programming') return 'Visual Programming'
    else if(track==='JavaScript') return 'JavaScript Programming'
    else return track
  }

  // sessionStorage.setItem('language', selected);
  return (
    <>
      <div className="pyui_dropdown">
        <div
          className={`pyui_dropdown-inner ${isActive ? "expanded" : ""}`}
          onClick={(e) => setIsActive(!isActive)}
        >
          <span>{returnTrack(selected[index].track)}</span>
          {/* <i class="fa-solid fa-chevron-down"></i> */}
        </div>
        {isActive && (
          <>
            <ul className="pyui_dropdown-list">
              {selected.map((item, index) => (
                <li
                  onClick={(e) => {
                    // setSelected(e.target.textContent);
                    setselecttrack(selected[index].track)
                    setIndex(index)
                    setIsActive(false);
                  }}
                  className="pyui_dropdown-list-item"
                >
                  {returnTrack(item.track)}
                </li>
              ))}
            </ul>

            {/* {toEnableDisable()
              ? (<li
                onClick={(e) => {
                  setSelected(e.target.textContent);
                  setIsActive(false);
                }}
                className="pyui_dropdown-list-item"
              >
                Python
              </li>
              ) : (
                <li
                  onClick={(e) => {
                    // setSelected(e.target.textContent);
                    setIsActive(false);
                  }}
                  className="pyui_dropdown-list-item disabled"
                >
                  Python
                </li>
              )} */}

            {/* {toEnableDisable()
              ? (<li
                onClick={(e) => {
                  setSelected(e.target.textContent);
                  setIsActive(false);
                }}
                className="pyui_dropdown-list-item"
              >
                Visual Programming
              </li>
              ) : (
                <li
                  onClick={(e) => {
                    // setSelected(e.target.textContent);
                    setIsActive(false);
                  }}
                  className="pyui_dropdown-list-item disabled"
                >
                  Visual Programming
                </li>
              )} */}


            {/* {process.env.REACT_APP_SHOW_JAVASCRIPT === 'true'
              ? (
                <li
                  onClick={(e) => {
                    // setSelected(e.target.textContent);
                    setIsActive(false);
                  }}
                  className="pyui_dropdown-list-item disabled"
                >
                  Javascript
                </li>
              ) : (
                <>
                </>
              )
            } */}

          </>
        )}
      </div>
    </>
  );
};

export default SelectLang;