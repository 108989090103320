import React from "react";
import { useState } from "react";
import "./SelectLang.scss";

const SelectGroup = (props) => {


  const [index, setIndex] = useState(0)
//   let language = sessionStorage.getItem('language');
  let { isActive, setIsActive, selected,
setselecttrack } = props;






  return (
    <>
      <div className="pyui_dropdown">
        <div
          className={`pyui_dropdown-inner ${isActive ? "expanded" : ""}`}
          onClick={(e) => setIsActive(!isActive)}
        >
          <span>{(selected[index].user_group)}</span>
        </div>
        {isActive && (
          <>
            <ul className="pyui_dropdown-list" style={{height:'24rem',overflow:'auto'}}>
              {selected.map((item, index) => (
                <li
                  onClick={(e) => {
                    setselecttrack(selected[index].user_group)
                    setIndex(index)
                    setIsActive(false);
                  }}
                  className="pyui_dropdown-list-item"
                >
                  {(item.user_group)}
                </li>
              ))}
            </ul>

    

          </>
        )}
      </div>
    </>
  );
};

export default SelectGroup;