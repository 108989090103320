import CryptoJS from 'crypto-js';
class ApiConfig{
constructor()
{
    
 let uri = 'https://seal-app-39wgb.ondigitalocean.app/api'
//let uri = 'http://localhost:8090/api';
    this.BaseURI=uri;
    this.client_public_key='baef7468287a44d8ac3634026d9fb8d1'
    this.client_secret_key='181a2f3171117c1bd164c88b1171c1b83114fc1712121b12'
}
addApiKey(uri)
  {
    
    // console.log(uri)
    // console.log("encode api key",Base64.encode(this.client_public_key))
    if(uri.includes('?'))
    {

      uri+=`&clientpublic=${this.client_public_key}&clientsecret=${this.client_secret_key}`
    }else{
      uri+=`?clientpublic=${this.client_public_key}&clientsecret=${this.client_secret_key}`
    }
    if(process.env.REACT_APP_SHOW_ISENCRYPT==="true"){
      const secretKey = 'don';
      const url = uri;
      const splitUrl = url.split('?');
      const baseUrl = splitUrl[0];
      const queryString = splitUrl[1];
      const encryptedQueryString = CryptoJS.AES.encrypt(queryString, secretKey).toString();
      const encodedQueryString = encodeURIComponent(encryptedQueryString);
      const encryptedUrl = `${baseUrl}?encodedQueryString=${encodedQueryString}`;
      return encryptedUrl
    }
    else{
      return uri;
    }

  }

getUserList()
{
    return `${this.BaseURI}/userlist`
}
}
export default ApiConfig;